.coloreado-diluido {
  background-color: rgb(170, 207, 220)!important;
  color: #fff!important;
  .dropdown {
    background-color: transparent!important;
    color: #fff!important;
  }
}

.coloreado-diluido-eliminado{
  background-color: rgb(196, 65, 93)!important;
  color: #fff!important;
  .dropdown {
    background-color: transparent!important;
    color: #fff!important;
  }
}