// COLORES.

// Standards
$white: #fff;
$black: #000;
$darkGrey: #666;
$lightGrey: #ddd;

// Colores corporativos
$primary: #FF6D29;
$secondary: #222;

$body-color: #212529;
$body-bg: $white;

// Colores contextuales:
$success: #9dcc4b;
$danger: #dc3545;
$info: #2538aa;

// Tables scale
$header-1-bg: #a3a09d;
$header-2-bg: #b8b6b4;
$row-odd-color: $body-color;
$row-odd-bg: $white;
$row-even-color: #ababab;
$row-even-bg: #ddd;

// Links
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);