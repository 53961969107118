#fosoTable1{
  .table{
    tbody{
      tr{
        td {
          border: 1px solid #eee;
          padding-top: 4px;
        }
      }
    }
  }
}

.fosoVerde{
  background: #95cc64;
}

.fosoHoy {
  background: #b5b5b5;
  // border: none !important;
}

.codigo-extraccion{
  background: #a5d9f7;
  padding: 4px 10px;
}
