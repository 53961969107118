.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #fff4e6!important;
  color: #868686;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #FF6D29;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #FF6D29;
    color: #ffedd6;
}

.InputFromTo {
  width: 100%;
  input{
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 6px 5px;
  }
  .DayPickerInput {
    display: inline-block;
    width: 100%;
  }
  .DayPickerInput-Overlay {
    /* width: 350px; */
    padding: 10px;
    z-index: 2;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
    font-size: 10px;
    &:hover{
      background-color: #fff4e6!important;
      color: #868686;
    }
  }
  .InputFromTo-to {
    input{
      width: 100%;
      border-radius: 3px;
      border: 1px solid #ccc;
      padding: 6px 5px;
    }
    .DayPickerInput-Overlay {
      margin-left: -289px;
    }
  }
  .DayPicker-Months{
    flex-wrap: nowrap!important;
    .DayPicker-Month{
      .DayPicker-Caption{
        div{
          font-size: 10px;
        }
      }
      .DayPicker-Weekdays{
        .DayPicker-WeekdaysRow{
          .DayPicker-Weekday{
            font-size: 10px;
          }
        }
      }
    }
  }
}
.datepicker-indv{
  .DayPickerInput{
    width: 100%;
  }
}

.DayPickerInput .form-control {
  font-size: 12px;
}


.help-block {
  height: 13px;
  display: inline-block;
  vertical-align: sub;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  svg{
    font-size: 14px;
  }
}

.error-fecha, .has-error {
  label{
    color: #a94442;
  }
  .DayPickerInput{
    input{
      border-color: #a94442;
      color: #a94442;
    }
  }
}

.datepicker-inline {
  display: inline;

  .control-label {
    display: inline;
    margin-right: 12px;
  }

  .DayPickerInput {
    display: inline;

    .form-control {
      display: inline;
      min-width: 1px;
      width: auto;
    }
  }
}

.inline-block .InputFromTo {
  position: relative;

  .DayPickerInput {
    width: calc(50% - 5px);
    margin-right: 5px;
    display: inline-block;

    & ~ .InputFromTo-to {
      display: inline-block;
      width: calc(50% - 5px);

      &:before {
        content: '-';
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 49%;
      }

      .DayPickerInput {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.clear-fecha{
  padding: 5px 3px;
  border-radius: 4px;
  position: absolute;
  top: -3px;
  right: 4px;
  font-size: 17.5px;
  color: #d1d1d1;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  &:hover{
    color: #9e9e9e;
  }
}

.cont-input-range{
  position: relative;
  display: inline-block;
  width: 46%;
}

.cont-input-date{
  position: relative;
  display: inline-block;
  width: 100%;
}