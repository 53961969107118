@font-face {
    font-family: 'opens-sans';
    font-style: normal;
    font-weight: 300;
    src: url(font/OpenSans-Light.woff);
}

@font-face {
    font-family: 'opens-sans';
    font-style: normal;
    font-weight: 400;
    src: url(font/OpenSans.woff);
}

@font-face {
    font-family: 'opens-sans';
    font-style: bold;
    font-weight: 600;
    src: url(font/OpenSans-Bold.woff);
}

/*@font-face {
    font-family: 'opens-sans';
    font-style: bold;
    font-weight: 800;
    src: url(OpenSans-Bold.woff);
}*/
