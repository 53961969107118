.alert {
  padding: 5px 15px;
  border-width: 0;
  margin-bottom: 15px;
}
.alert-success {
  background: #9dcc4b;
  color: #fff;
}
.alert-danger {
  background: #dc3545;
  color: #fff;
}