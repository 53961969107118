
textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 75px;

  & ~ .input-group-addon ~ .help-block > span,
  & ~ .help-block > span {
    vertical-align: super;
  }
}