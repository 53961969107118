.filtros-tabla-modal-verracos{
  display: block;
  background: #f1f1f1;
  width: 100%;
  height: 30px;
  clear: both;
  border: 1px solid #d1d1d1;
  margin-bottom: 5px;
  .btn-icono{
    background: #999;
    color: #fff;
    border: none;
    padding: 2px 6px;
    outline: none;
    margin-right: 16px;
    float: left;
    margin-top: 4px;
    border-radius: 5px;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .nuevo{
    margin: 0;
    padding: 1px 6px;
    margin-left: 0px;
    margin-top: 3px;
    float: left;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .col-sm-6{
    width: 47px;
  }
}
.oculta-action{
  .btn-icono{
    display: none;
  }
}

.error-verracos{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
  }
}
.noerror-verracos{
  display: none;
}
.tabla-filtros-modal-verracos{
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding-top: 7px;
  .buttons-min{
    text-align: right;
    .button-aplicar{
      margin: 0 5px 5px 0px;
    }
  }
}
.btn > svg {
  vertical-align: middle;
}

.planingRojo{
  background: #dc1c1c;
}

.planingAzul{
  background: #1c8cdc;
}

.planingAmarillo{
  background: #e6d349;
}
.alto-tablas-planing{
  .table{
    thead{
      display: block;
      width: 100%;
      tr{
        max-height: 75px;
        height: 75px;
        display: flex;
        th{
          /* border: 1px solid #eee; */
          height: 75px;
          padding-top: 8px;
          span{
            writing-mode: vertical-rl;
            text-orientation: sideways-right;
            transform: rotate(180deg);
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    tbody{
      display: block;
      width: 100%;
      overflow-x: scroll;
      height: calc(100vh - 320px);
      tr{
        display: flex;
        td{
          border: 1px solid #eee;
          padding-top: 4px;
        }
      }
    }
  }
}

.tabla-fechas-planing{
  .table{
    thead{
      tr{
        th{
          span{
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.print {
  background: #999!important;
  border: none!important;
  svg{
    font-size: 15px;
    margin-right: 4px;
    vertical-align: sub;
  }
}

.campo-visible{
  display: none;
}

.simple-table{
  .fila-seleccionada-planing{
    background: rgba(206, 17, 38, 0.2)!important;
    &:hover{
      background: rgba(206, 17, 38, 0.2)!important;
    }
  }
}

.totales-planing{
  background: #eee;
  border: 1px solid #d1d1d1;
  display: flex;
  .planing-total-verraco {
    padding: 7px;
    display: block;
    text-align: center;
    border-right: 1px solid #d1d1d1;
  }
}

.planning-total-dosis-pedido{
  background: #eee;
}

.planning-total-dosis-planning{
  background: #DCDCDC;
}

.alto-tablas-planing{
  border-right: 2px solid #797979;
  box-sizing: border-box;
  width: 100%;
  margin-bottom:20px;
  /*height: calc(100vh - 240px);*/
}

.custom-form {
  width: 100%;
}

.custom-form.col, .custom-form > [class*=col-]  > [class*=col-]{
  padding-right: 5px;
  padding-left: 5px;
}

.modal-exportar-imprimir-planing {
  .modal-dialog{
    width: 300px;
  }
}

.botones-footer-planing{
  text-align: right;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.error-eleccion-exportar-imprimir {
  color: #a71605;
  svg{
    font-size: 16px;
    vertical-align: bottom;
  }
}

.modal-sugerencias-planing{
  .modal-dialog{
    width: 99%;
    .tabla-filtros{
      display: none;
    }
    .open-filters{
      display: block;
    }
    .aplicar-tabla-filtros{
      border-left: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      border-top: 1px solid #d1d1d1;
      padding: 10px 10px 5px 10px;
    }
    .no-borde-filtros{
      border-left: none!important;
      border-right: none!important;
      border-top: none!important;
      padding: 0 !important;
    }
  }
}

.contenedor-tables-sugerencias {
  border: 1px solid #eee;
  height: 300px;
  overflow: auto;
}

.botones-asignar-verracos{
  text-align: center;
  .asignar-verracos{
    display: block;
    width: 50px;
    margin: 15px auto;
  }
}
.ancho-tablas-sugerencias{
  width: 47%;
}
.ancho-botones{
  width: 6%;
  padding-left: 0;
  padding-right: 0;
}

.anadir-verracos-sin-extraer {
  margin-right: 15px;
  svg{
    font-size: 16px;
    margin-right: 3px;
  }
}

.alto-tablas-planing > table > tbody > tr > td.checkbox-col [class*="col-sm"] .form-group .checkmark {
  top: -4px;
  left: -1px;
}

.boton-anadir-planing{
  margin-right: 20px;
  svg{
    font-size: 13px;
    margin-right: 2px;
    vertical-align: middle;
  }
}

.checkbox-planing-principal{
  width: 26px;
}
.acciones-tabla-filtros .checkbox-planing-principal [class*="col-sm"]{
  padding-right: 0px!important;
  padding-left: 0px!important;
}

.checkbox-planing-principal{
  .checkbox-inline{
    vertical-align: super;
  }
}

.filtros-planning {
  .cont-input-date{
    margin-top: 3px;
    .DayPickerInput{
      input{
        height: 22px;
        padding: 4px;
      }
    }
    .clear-fecha {
      padding: 1px 3px;
    }
  }
}

@media(min-width: 1380px) {
  .tabla-planning-izq{
    width: 554px!important;
  }
  .tabla-planning-der{
    width: calc(100% - 555px);
  }
}

.alerta-planing-sin-verracos{
  color: #ad0e0e;
  font-weight: bold;
  svg{
    font-size: 15px;
    vertical-align: sub;
  }
}

.modal-duplicar-planing{
  .col-sm-12{
    padding-left: 0;
    padding-right: 0;
  }
}

.anadirquitarsugerencias{
  padding-top: 0px;
  margin-top: 4px;
  padding-bottom: 0px;
  svg{
    vertical-align: bottom!important;
  }
}
