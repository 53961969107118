$header-height: 95px;
$footer-height: 20px;
$main-container-height: 100vh;
$main-container-min-height: 550px;
.nav-link{
  line-height: 60px;
  color: #5a5a5a;
  font-size: 14px;
  padding: 0 18px;
  outline: 0;
  display: flex;
  align-items: center;
  &:hover{
    text-decoration: none;
  }
}

body{
  background: #eee;
  header{
    height: $header-height;
    .be-top-header {
      height: 50px;
      background-color: #fff;
      border: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #e6e6e6;
      padding: 0;
      .navbar-header{
        width: 25.666667%;
        .navbar-brand {
          padding-top: .75rem;
          padding-bottom: .75rem;
          font-size: 1rem;
          img {
            transform: translateY(-50%);
            top: 50%;
            position: relative;
          }
        }
      }
      .right-navbar {
        padding-right: 20px;
        width: calc(100% - 25.666667%)!important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .loged {
          display: flex;
          align-items: center;
          padding-top: 5px;
          .select-centro-inseminacion{
            width: 215px;
            .form-group{
              margin-bottom: 0;
            }

          }
          .avatar{
            max-width: 32px;
            max-height: 32px;
            border-radius: 50%;
            margin-left: 20px;
          }
          .user-data{
            font-size: 13px;
            line-height: 18px;
            text-transform: initial;
            padding-left: 5px;
            .data-cargo {
              text-transform: uppercase;
              font-weight: 800;
              color: #777;
              font-size: 11px;
            }
          }
        }
        .notifications{
          margin-left: 15px;
          cursor: pointer;
          svg{
            font-size: 22px;
            color: #ccc;
            &:hover{
              color: #FF6D29;
            }
          }
        }
      }
    }
  }
  .sidebar{
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 100;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    background-color: #eee;
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
    ul{
      margin-bottom: 0;
      padding-left: 0;
      .menu-toggle {
        height: 50px;
        background: #222;
        color: #fff;
        line-height: 65px;
        text-align: center;
        cursor: pointer;
      }
      .item-menu{
        list-style: none;
        float: left;
        border-left: 1px solid #e2e2e2;
        a{
          display: flex;
          align-items: center;
          padding: 10px 3px;
          font-size: 14px;
          color: #666;
          justify-content: center;
          svg{
            font-size: 16px;
            margin-right: 3px;
            color: #666;
            margin-left: 0;
          }
          .arrow-down{
            position: absolute;
            right: 15px;
            text-align: center;
            width: 15px;
            height: 20px;
            svg{
              margin-left: 0;
              font-size: 17px;
            }
          }
          &:hover{
            text-decoration: none;
            background: #FF6D29;
            color: #fff;
            svg{
              color: #fff;
            }
          }
          &:active{
            text-decoration: none;
          }
          &:focus{
            text-decoration: none;
          }
        }
        .visible{
          display: block;
          position: absolute;
          background: #eee;
          top: 39px;
          box-shadow: 0 7px 9px rgba(0, 0, 0, 0.1);
          li{
            a{
              color: #666;
              justify-content: left;
              padding-top: 6px;
              padding-bottom: 7px;
              font-size: 13px;
              &:hover{
                color: #fff;
              }
            }
          }
        }
        ul{
          display: none;
          background: #fbfbfb;
          li{
            list-style: none;
            font-size: 12px;
            color: #777;
            border-bottom: 1px solid #e2e2e2;
            a{
              padding-left: 21px;
          
              padding-right: 17px;
            }
            ul{
              display: block;
              li{
                a{
                  padding-left: 35px;
                  font-weight: normal;
                  text-transform: none;
                  padding-right: 18px;
                }
              }
            }
          }
        }
      }
      .item-dashboard{width: 8%;}
      .item-verracos{
        width: 8%;
        .font-gesipor-cerdo{
          &:before {
              content: "\E900";
              font-size: 15px;
              margin-right: 3px;
          }
        }
      }
      .item-plan-sanitario{width: 10%;}
      .item-extracciones{
        width: 9%;
        .visible{
          width: 9%;
        }
      }
      .item-planing{width: 8%;}
      .item-pedidos{width: 9%;}
      .item-iformes{width: 8%;}
      .item-almacen{width: 8%;}
      .item-mantenimiento-datos{
        width: 15%;
        .visible{
          width: 15%;
        }
      }
      .item-herramientas{width: 10%;}
      .item-agenda{width: 7%;}
      .item-sistema{width: 7%;}
      .item-ayuda{width: 6%;}
    }
  }
  .main-container {
    /* height: $main-container-height; */
    min-height: $main-container-min-height;
  }
  .public-layout .main-container {
    background:#fff;
  }
  .admin-layout header {
    height: 50px;
    .right-navbar {
      height: 50px;
      .loged {
        padding-top: 0;
        .nav-link {
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }
  footer {
    height: $footer-height;
    text-align: center;
    .footer-link{
      color: #FF6D29;
      cursor: pointer;
      &:hover{
        color: #000;
      }
    }
  }
}

.open-m{
  .sidebar{
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 60px;
    .menu-lateral-izq{
      ul{
        padding-left: 0;
        li{
          list-style: none;
          text-align: center;
          a{
            .titulo-item{
              display: none;
            }
            .arrow{
              display: none;
            }
          }
          ul{
            display: none;
          }
        }
      }
    }
  }

  .main-container {
    width: calc(100% - 70px);
    margin-left: 70px;
  }
}

/* BARRA SCROLL */
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius:10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999; 
}
/* FIN BARRA SCROL */


/* POPOVER USER */
.content-popover-user{
  border-radius: 0;
  padding: 0;
  width: 250px;
  max-width: 250px;
  .popover-content{
    padding: 0;
    .user-popover{
      padding: 30px 25px;
      background: #FF6D29;
      color: #fff;
    }
    .mi-cuenta-popover{
      background: #fff;
      padding: 10px;
      ul{
        padding: 0;
        li{
          list-style: none;
          padding: 10px;
          cursor: pointer;
          min-height: 47px;
          .btn.no-bg {
            color: #212529;
          }
          &:hover{
            background: #eee;
            .btn.no-bg {
              background: #eee;
            }
          }
          svg{
            font-size: 22px;
          }
          .acciones-mi-cuenta-popover{
            font-size: 15px;
            vertical-align: top;
            margin-left: 9px;
          }
        }
      }
    }
  }
  .arrow{
    &:after{
      border-bottom-color: #FF6D29!important;
    }
  }
}
/* FIN POPOVER USER */

/* POPOVER NOTIFICATIONS */
.content-popover-notification{
  border-radius: 0;
  padding: 0;
  width: 390px;
  max-width: 390px;
  .notificaciones-popover{
    .titulo-notificaciones{
      text-align: center;
      display: block;
      margin-bottom: 10px;
      .num-notificaciones{
        font-size: 11px;
        background: #FF6D29;
        border-radius: 50%;
        padding: 3px 7px;
        margin-left: 4px;
        color: #fff;
        vertical-align: text-bottom;
      }
    }
    ul{
      padding: 0;
      li{
        list-style: none;
        padding: 10px;
        cursor: pointer;
        border-top: 1px solid #eee;
        &:hover{
          background: #eee;
        }
      }
    }
  }
}
/* FIN POPOVER NOTIFICATIONS */

.icono-sistema{
  svg{
    color: #ccc;
    border-left: 1px solid #eee;
    padding-left: 6px;
    margin-left: 5px;
    cursor: pointer;
    &:hover{
      color: #FF6D29;
    }
  }
}
.icono-ayuda{
  svg{
    color: #ccc;
    border-left: 1px solid #eee;
    padding-left: 6px;
    margin-left: 5px;
    cursor: pointer;
    &:hover{
      color: #FF6D29;
    }
  }
}

.sistema-popover{
  p{
    font-weight: bold;
  }
  svg{
    font-size: 15px;
    vertical-align: text-bottom;
    margin-right: 7px;
  }
  ul {
    padding: 0;
    li{
      list-style: none;
      cursor: pointer;
      border-top: 1px solid #eee;
      padding-top: 5px;
      padding-bottom: 5px;
      &:hover{
        background: #eee;
      }
    }
  }
}

/* MENU HASTA 1024px */
@media (max-width: 1244px) {
   body{
    .sidebar{
      ul{
        .item-menu{
          a{
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 1167px) {
  body{
    header {
      height: $header-height - 10px;
    }
    .sidebar{
      margin: 0 auto;
      width: 100%;
      ul{
        .item-menu{
          a{
            padding: 7px 2px 7px 1px;
            font-size: 11px;
          }
          .visible{
            top: 29px;
          }
        }
      }
    }
  }
}

.menu-desktop{display: block}
.menu-responsive{display: none}
.open-m .sidebar {
    flex: 0 0 auto;
    max-width: 100%;
}

.logo-desktop{
  display: block!important;
}
.logo-responsive{
  display: none!important;
}

@media (max-width: 1000px){
  .main-container{
    width: 75%;
  }
  .open-m .sidebar {
    flex: 0 0 auto;
    max-width: 60px;
  }
  .sidebar-container{
    width: 25%;
    .sidebar{
      margin: 0;
      width: 25%;
      padding: 0;
      overflow-y: scroll;
      height: calc(100vh - 48px);
      .menu-lateral-izq{
        min-height: 100vh;
        overflow: auto;
        ul{
          .item-menu{
            float: none;
            a{
              justify-content: left;
              padding: 10px 2px 9px 10px;
              font-size: 12px;
              svg{
                font-size: 17px;
                margin-left: 5px;
              }
              .font-gesipor-cerdo{
                font-size: 17px;
                margin-left: 5px;
                margin-right: 3px;
              }
            }
            .visible{
              position: initial;
              background: #fff;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .menu-desktop{display: none}
  .menu-responsive{display: block}
}

@media (max-width: 800px){
  .logo-desktop{
    display: none!important;
  }
  .logo-responsive{
    display: block!important;
  }
  .private-layout{
    padding: 0;
  }
  body{
    .main-container{
      width: calc(100% - 70px);
      margin-left: 70px;
    }
  }
  .sidebar-container{
    padding: 0;
    .sidebar{
      width: 250px;
      height: 100%;
    }
  }
}

@media (max-width: 770px){
  body{
    header{
      .be-top-header{
        .navbar-header{
          width: 16.666667%;
          float: left;
        }
        .right-navbar{
          .loged{
            .select-centro-inseminacion {
              width: 130px;
            }
          }
        }
      }
    }
  }
}
