.content-informe-estado-centro{
  h3{
    background: #FF6D29;
    color: #fff;
    padding: 7px 15px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 18px;
  }
  p{
    font-size: 15px;
  }
}
.totales-informes{
  border: 1px solid #bfbbbb;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  .titulo-totales-informes{
    background: #ddd;
    border-right: 1px solid #bfbbbb;
  }
  .valores-totales-informes{
    border-right: 1px solid #bfbbbb;
  }
  span{
    padding: 5px;
    text-align: center;
    display: block;
  }
}

.campo-visible{
  display: none!important;
}

.stock-dosis-verraco{
  background: #FF6D29;
}
.stock-dosis-pool{
  background: rgb(37, 56, 170);
}

.contenedor-grafica{
  overflow-x: scroll;
}

.contenedor-tablas-productividad-extracciones, .contenedor-tablas-seguimiento-extracciones-pool{
  height: 340px;
  overflow: auto;
  border: 1px solid #eee;
}

.modal-productividad-verracos {
  .modal-dialog{
    width: 96%;
  }
}

.tab-seguimiento-extracciones-pool{
  .nav-tabs{
    margin-bottom: 0px;
    .active{
      a{
        color: #FF6D29;
        border-bottom: 3px solid;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        &:focus, &:hover, &:active, &:visited{
          color: #FF6D29;
          border-bottom: 3px solid;
          border-top: 3px solid transparent;
          border-right: none;
          border-left: none;
        }
      }
    }
    a{
      color: #666;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-right: none;
      border-left: none;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      &:focus, &:active, &:visited{
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
      &:hover{
        color: #FF6D29;
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
    }
  }
}

#informeSeguimientoExtraccionesPoolTable{
  margin-top: 20px;
}

.contenedor-tablas-informes-clientes{
  height: 360px;
  overflow: auto;
  border: 1px solid #eee;
  margin-bottom: 15px;
}

.fila-seleccionada-informes {
  background: rgba(206, 17, 38, 0.2) !important;
  &:hover{
    background: rgba(206, 17, 38, 0.2) !important;
  }
}

.simple-table > tbody > .fila-seleccionada-informe:hover {
  background: rgba(206, 17, 38, 0.2) !important;
}

.extracciones-sin-dosis{
  background: red;
}

.totales-informe-pro-extracciones{
  background: #eee;
  width: 12%;
  display: block;
  float: left;
  margin-top: 12px;
  padding: 5px;
  margin-bottom: 20px;
}

.productivo{
  background: #14af2e;
}
.no-productivo{
  background: red;
}

.nom-raza-productividad-centro{
  margin: 0;
}

.grafica-dosis-formas-anormales{
  position: relative;
  .label-dosis-reales-teoricas{
    position: absolute;
    top: 50%;
    left: -86px;
    transform: rotate(270deg);
  }
  .label-formas-anormales{
    position: absolute;
    top: 48%;
    right: -65px;
    transform: rotate(270deg);
  }
}

.edad-estado-centro{
  background: #eee;
  padding: 7px;
  margin-right: 10px;
  margin-top: 15px;
  display: inline-block;
  border: 1px solid #d1d1d1;
  vertical-align: top;
  width: 100%;
  b{
    font-size: 15px;
  }
}

.tabla-edad-estado-centro {
  margin-bottom: 0;
  margin-top: 3px;
  .total-verracos {
    border-right: 1px solid #ddd;
    background: #a3a09d;
    color: #fff;
  }
}

.tabla-edad-estado-centro-medias {
  margin: 0;
  padding: 0;
  .total-verracos-padre{
    padding-left: 0;
    padding-right: 0;
    .total-verracos {
      border-right: 1px solid #ddd;
      background: #b8b6b4;
      color: #fff;
    }
  }
}

.medias-edad-verracos-padre{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .cont-medias-edad-verracos{
    padding-right: 0;
    padding-left: 0;
    .medias-edad-verracos{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
    }
  }
}

.tabla-numero-extracciones{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .tabla-numero-extracciones-padre{
    padding-right: 0;
    padding-left: 0;
    .numero-extracciones{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #a3a09d;
      color: #fff;
    }
  }
}
.tabla-numero-extracciones-body{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .tabla-numero-extracciones-padre-body{
    padding-right: 0;
    padding-left: 0;
    .numero-extracciones-body{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #fff;
    }
  }
}

.calidad-eyaculado-result {
  display: block;
  padding: 5px 10px;
  text-align: center;
}

.tabla-ritmo-extraccion{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-5{
    width: 20%;
  }
  .tabla-ritmo-extraccion-padre{
    padding-right: 0;
    padding-left: 0;
    .ritmo-extraccion{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #a3a09d;
      color: #fff;
    }
  }
}
.tabla-ritmo-extraccion-body{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-5{
    width: 20%;
  }
  .tabla-ritmo-extraccion-padre-body{
    padding-right: 0;
    padding-left: 0;
    .ritmo-extraccion-body{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #fff;
    }
  }
}

.tabla-productividad-centro{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-7{
    width: 14.286%
  }
  .tabla-productividad-centro-padre{
    padding-right: 0;
    padding-left: 0;
    .productividad-centro{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #a3a09d;
      color: #fff;
    }
  }
}
.tabla-productividad-centro-body{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-7{
    width: 14.286%;
  }
  .tabla-productividad-centro-padre-body{
    padding-right: 0;
    padding-left: 0;
    .productividad-centro-body{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #fff;
    }
  }
}

.tabla-eliminacion-verracos{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-7{
    width: 14.286%
  }
  .tabla-eliminacion-verracos-padre{
    padding-right: 0;
    padding-left: 0;
    .eliminacion-verracos{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #a3a09d;
      color: #fff;
    }
  }
}
.tabla-eliminacion-verracos-body{
  border: 1px solid #ddd;
  margin: 0;
  padding: 0;
  .col-7{
    width: 14.286%;
  }
  .tabla-eliminacion-verracos-padre-body{
    padding-right: 0;
    padding-left: 0;
    .eliminacion-verracos-body{
      padding: 7px;
      text-align: center;
      border-right: 1px solid #ddd;
      display: block;
      background: #fff;
    }
  }
}

.productividad-verraco-index{
  width: 10%!important;
}

.p-raza-linea{
  margin: 3px 0 0px;
  font-size: 12px !important;
  padding: 2px 10px;
  border-top: 1px solid #dedede;
}

.numero-extraciones-raza-linea {
  .col-sm-2{
    width: 14.28%;
  }
  .col-sm-4{
    width: 28.5%;
  }
}

.promedio-edad-raza-linea{
  .col-sm-3{
    width: 20%;
  }
}

.edad-estado-centro-raza-linea{
  .col-sm-3{
    width: 20%;
  }
}

.contraer-grafica{
  display: none;
}

.btn-desplegar-grafica{
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  margin-top: 30px;
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: left;
  svg{
    font-size: 20px;
    vertical-align: top;
  }
  &:focus{
    background: none;
    color: #333!important;
    border-color: #ddd;
    outline: none!important;
    background-color: #fff!important;
    border-color: #ddd!important;
  }
  &:active{
    background: none;
    color: #333!important;
    border-color: #ddd;
    outline: none;
    box-shadow: none!important;
    background-color: #fff!important;
    border-color: #ddd!important;
  }
  &:hover{
    background: none;
    color: #333!important;
    border-color: #ddd;
    outline: none!important;
    background-color: #fff!important;
    border-color: #ddd!important;
  }
}

.medias-inf-extracciones{
  border: 1px solid #bfbbbb;
}

.es-intervencion{
  background: #c14312;
}

.regilla-informe-pers {
  background: #eee;
  width: auto;
  border: 1px solid #d1d1d1;
  margin-top: 20px;
  padding: 7px 0px 7px 0px;
  margin: 12px 16px;
  span{
    padding: 7px 14px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    &:hover{
      background: #FF6D29;
      color: #fff;
    }
  }
  .regilla-activa{
    background: #FF6D29;
    color: #fff;
  }
}

.barra-filtros-inf-pers {
  margin-top: 0px;
  .acciones-tabla-filtros{
    height: 39px;
    .selector-informe-personalizado{
      padding-right: 6px !important;
    }
    .buttons-flex{
      .mostrar-seleccion-personalizado{
        height: 28px !important;
        margin-top: 1px;
        padding: 0px 12px;
        svg{
          font-size: 18px;
        }
      }
    }
  }
}
