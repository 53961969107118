@import "../../assets/scss/variables";

.form-analisis-extracciones {
  margin-right: -15px;
  margin-left: -15px;

  .page-title{
    font-size: 15px!important;
    .record-pagination{
      right: 34px;
      top: 13px;
    }

    .record-pagination > .btn {
      margin-top: 0;
    }
  }
  .row {
    margin-right: -5px;
    margin-left: -5px;
    [class*="col-sm"], .col-auto {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  hr{
    margin-top: 5px;
    margin-bottom: 5px;
  }


  .form-subtitle {
    font-size: 13px !important;
  }
  & :not(.table-container) .form-group {
    margin-bottom: 5px;
    font-size: 11px;
    .control-label {
      margin-bottom: 2px;
      font-size: 10px;
      margin-right: 5px;
    }
    .form-control, .InputFromTo input {
      height: 25px;
      font-size: 11px;
    }
    [type="textarea"] {
      min-height: 25px;
      height: auto !important;
      resize: vertical;
    }
  }

  .read-only-fields {
    background: rgba(238, 238, 238, 0.2);
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 0;
    margin-left: 0;

    .form-group {
      display: flex;
      align-items: center;
      .form-control {
        background: transparent;
        border: 1px;
        box-shadow: none;
        text-align: center;
      }
    }
  }

  .btn {
    height: 25px;
    margin-top: 17px;
    font-size: 11px;
  }
  .botones-pie-form .btn {
    text-align: right;
  }
}

.cierre-ventana-extracciones{
  float: right;
  svg{
    color: #969696;
    margin-top: 6px;
    font-size: 19px;
  }
}

#barcode-canvas{
  display: none;
}