@import "../../../assets/scss/variables";

.btn.btn-primary {
  background: $primary;
  color: $white !important;
  border: 1px solid $primary;
  &:hover, &:active, &:focus {
    background: $secondary;
    border-color: $secondary;
  }
}