.cont-part-dashboard{
  padding-right: 0;
  padding-left: 12px;
  .part-dashboard{
    /*height: 230px;
    background: #f1f1f1;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid #d1d1d1;
    margin-top: 12px;*/
    height: 250px;
    padding: 8px;
    border-radius: 1px;
    border: 1px solid #d1d1d1;
    margin-top: 12px;
    canvas{
      margin: 15px auto;
    }

    &.m-a-0_to_canvas canvas {
      margin: 0
    }

    h3{
      font-size: 11px !important;
      background: #eee;
      padding: 6px 20px;
      display: table;
      margin-top: 0;
      border-radius: 6px;
      font-weight: bold;
      color: #777;
      display: block;
    }
    .estados-total-verraco{
      background: #e2e2e2;
      padding: 7px 10px;
      border: 1px solid #c1c1c1;
      margin-top: 10px;
    }
    .total-verracos{
      background: #d1d1d1;
      padding: 7px 10px;
      border: 1px solid #c1c1c1;
      margin-top: 10px;
    }
    .tarea-dashboard{
      background: #FF6D29;
      padding: 1px 10px;
      border-radius: 4px;
    }
  }
}

.ir-agenda-dashboard {
  float: right;
  font-size: 11px;
  margin-bottom: 2px;
  margin-top: 1px;
  margin-right: 1px;
}

.ir-almacen-dashboard {
  float: right;
  font-size: 11px;
  margin-bottom: 2px;
  margin-top: 1px;
  margin-right: 1px;
}

.label-recta{
  position: absolute;
  bottom: 1px;
  left: 50%;
  font-size: 10px;
}
