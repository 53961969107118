.modal-linea-genetica {
  .modal-dialog{
    width: 390px;
  }
}

.tablaTotales{
  background: #eee;
  border: 1px solid #d1d1d1;
  margin: 20px 0px;
  .total-verracos{
    padding: 7px;
    display: block;
    background: #d4d4d4;
    text-align: center;
    span{
      font-weight: bold;
    }
  }
  .estados-total-verraco{
    padding: 7px;
    display: block;
    text-align: center;
    border-right: 1px solid #d1d1d1;
  }
}

.table>thead>tr>th {
  border-bottom: 1px solid #ddd;
}

.borderDobleHeadeClass{
  border-left: 1px solid #ddd!important;
}