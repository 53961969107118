.modal-visitas {
  .modal-dialog {
    width: 660px;
  }
  .imprimir-visita-modal{
    svg{
      font-size: 15px;
      margin-right: 5px;
    }
  }
}

#VisitaTable{
  table-layout: fixed;
  th, td {
    word-wrap: break-word;
    white-space: normal;
  }
}

