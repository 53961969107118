.page-title {
  position: relative;

  .record-pagination {
    position: absolute;
    right: 0;
  }
}

.modal-header {
  .record-pagination {
    position: absolute;
    top: 8px;
    right: 34px;
  }
  .close ~ .record-pagination {
    right: 42px;
  }
}
