.simple-modal{
  .modal-header{
    padding: 8px 15px;
    h3{
      color: #FF6D29;
      text-transform: uppercase;
    }
  }
  .modal-body{
    padding: 24px 15px;
    p{
      text-align: center;
      font-size: 16px;
    }
  }
  .modal-footer{
    padding: 8px 15px;
    .buttons-wrapper{
      .btn-white{
        margin-left: 10px;
      }
    }
  }
}