.select-causa-eliminado-visible{
  display: block;
}
.select-causa-eliminado-oculto{
  display: none;
}
.filtros-anadidos{
  .badge-dark{
    color: #fff;
    background-color: #FF6D29;
    margin-left: 6px;
    padding: 3px 8px;
    font-size: 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 0;
  }
}

.existe-cambio-estado{
  color: #d60000;
  svg{
    font-size: 17px;
    vertical-align: sub;
    margin-left: 14px;
    margin-right: 5px;
  }
}