@font-face {
  font-family: 'font-gesipor';
  src:  url('font/font-gesipor.eot?dskygn');
  src:  url('font/font-gesipor.eot?dskygn#iefix') format('embedded-opentype'),
    url('font/font-gesipor.ttf?dskygn') format('truetype'),
    url('font/font-gesipor.woff?dskygn') format('woff'),
    url('font/font-gesipor.svg?dskygn#font-gesipor') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="font-gesipor"], [class*=" font-gesipor"] {
  /* use !important to prevent issues with browser extensions that change font */
  font-family: 'font-gesipor' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-gesipor-cerdo:before {
  content: "\e900";
}

.font-gesipor-orderDesc:before {
  content: "\e901";
}

.font-gesipor-orderAsc:before {
  content: "\e902";
}

.font-gesipor-asc:before {
  content: "\e903";
}

.font-gesipor-desc:before {
  content: "\e904";
}
