@import "../../../assets/scss/variables";

.btn.btn-white {
  background: $white;
  color: $darkGrey;
  border: 1px solid $lightGrey !important;
  &:hover, &:active, &:focus {
    background: $secondary;
    color: $white;
  }

  a {
    color: $darkGrey;
    text-decoration: none;
  }
}