.content-panel-control{
  .nuevo{
    width: 100%;
    padding: 6px 2px;
    svg{
      font-size: 17px;
      vertical-align: bottom;
    }
  }
  .botones-acciones-panel{
    text-align: right;
  }
}

.modal-teclas-contaje{
  .modal-dialog{
    width: 450px;
  }
  .teclaContajeMas{
    text-align: center;
    font-size: 20px;
    background: #eee;
    display: block;
    margin-bottom: 5px;
  }
  .teclaContajeMenos{
    text-align: center;
    font-size: 20px;
    background: #eee;
    display: block;
    margin-bottom: 5px;
  }
}

.modal-tipo-diluyente{
  .modal-dialog{
    width: 800px;
  }
  .container-tabla-tipo-diluyente{
    margin-top: 20px;
    .filtros-tabla-tipo-diluyente{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-left: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .custom-check-w {
        width: 250px!important;
      }
    }
  }
}

.modal-tipo-dosis{
  .modal-dialog{
    width: 800px;
  }
  .container-tabla-tipo-dosis{
    margin-top: 20px;
    .filtros-tabla-tipo-dosis{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-left: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .custom-check-w {
        width: 250px!important;
      }
    }
  }
}

.modal-recta-colorimetro{
  .modal-dialog{
    width: 800px;
  }
  .container-tabla-recta-colorimetro{
    margin-top: 20px;
    .filtros-tabla-recta-colorimetro{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-left: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .nuevo{
        margin-top: 4px;
        padding: 1px 10px;
        font-size: 12px;
        margin-left: 16px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .custom-check-w {
        width: 250px!important;
      }
    }
  }
}

.modal-anadir-colorimetro{
  .modal-dialog{
    width: 800px;
  }
  .container-tabla-anadir-colorimetro{
    .filtros-tabla-anadir-colorimetro{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-right: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .nuevo{
        margin-top: 4px;
        padding: 1px 10px;
        font-size: 12px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .custom-check-w {
        width: 250px!important;
      }
    }
  }
}

.mostrar-campos-colorimetro, .mostrar-campos-muestras {
  display: none;
}

.filtros-tabla-modal-grupo-verracos{
  display: block;
  background: #f1f1f1;
  width: 100%;
  height: 30px;
  clear: both;
  border: 1px solid #d1d1d1;
  margin-bottom: 5px;
  .btn-icono{
    background: #999;
    color: #fff;
    border: none;
    padding: 2px 6px;
    outline: none;
    margin-right: 16px;
    float: left;
    margin-top: 4px;
    border-radius: 5px;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .nuevo{
    margin: 0;
    padding: 1px 6px;
    margin-left: 0px;
    margin-top: 3px;
    float: left;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .custom-check-w {
    width: 250px!important;
  }
  .col-sm-6{
    width: 47px;
  }
}

.alertas-panel{
  padding: 0 5px 0 0;
}

.imagenFirma{
    padding: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background: #fafafa;
    position: relative;
    .custom-file-label{
      position: absolute;
      top: 40px;
      /*left: 32px;*/
      cursor: pointer;
      span{
        display: block;
      }
      svg{
        font-size: 45px;
      }
    }
    .content-dropzone{
      position: relative;
      .dropzone{
        text-align: center;
        outline: none;
        width: 0;
        height: 0;
        border-right: 23px solid rgba(255, 142, 0, 0.7);
        border-top: 23px solid transparent;
        border-left: 21px solid transparent;
        border-bottom: 23px solid rgba(255, 142, 0, 0.7);
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        svg{
          font-size: 17px;
          color: #fff;
        }
        &:hover{
          border-right: 23px solid rgba(0,0,0,0.7);
          border-bottom: 23px solid rgba(0,0,0,0.7);
          svg{
            opacity: 0.8;
          }
        }
      }
      .imagen-firma{
        img{
          width: 100%;
        }
      }
      .error-upload-img-oculto{
        display: none;
      }
      .error-upload-img-visible{
        background: #d63737;
        display: inline-block;
        color: #fff;
        padding: 5px 22px;
        border-radius: 15px;
        position: absolute;
        top: 13px;
        right: 0;
      }
      .eliminar-imagen-firma{
        position: absolute;
        background: rgba(0,0,0,0.3);
        color: #fff;
        border-radius: 50%;
        padding: 5px 6px 2px 6px;
        font-size: 10px;
        right: 0;
        top: 0;
        cursor: pointer;
        svg{
          font-size: 16px;
        }
        &:hover{
          background: #222;
        }
      }
    }
  }

.modal-anadir-muestras{
  .modal-dialog{
    width: 425px;
  }
}

.tab-panel-control{
  .nav-tabs{
    margin-bottom: 30px;
    .active{
      a{
        color: #FF6D29;
        border-bottom: 3px solid;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        &:focus, &:hover, &:active, &:visited{
          color: #FF6D29;
          border-bottom: 3px solid;
          border-top: 3px solid transparent;
          border-right: none;
          border-left: none;
        }
      }
    }
    a{
      color: #666;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-right: none;
      border-left: none;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      &:focus, &:active, &:visited{
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
      &:hover{
        color: #FF6D29;
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
    }
  }
}
.leyenda-secuencias-numericas{
  background: #eee;
  border: 1px solid #d1d1d1;
  display: inline-block;
  margin: 15px;
  h3{
    font-weight: bold;
    margin-bottom: 15px;
  }
}

.modal-secuencias-numeracion{
  .modal-dialog{
    width: 860px;
  }
}
