.form-copia-seguridad{
  .modal-dialog{
    width: 320px;
    #idCentro{
      margin: 0 15px;
    }
  }
}

.modal-programar-copia{
  .col-sm-2{
    width: 14.28%;
  }
}

.archivos-copia-serguridad{
  border: 1px solid #d1d1d1;
  margin: 0 15px;
  outline: none;
  cursor: pointer;
  .custom-file-label{
    background: #eee;
    label {
      background: #d1d1d1;
      margin: 0;
      padding: 10px;
      outline: none;
      cursor: pointer;
      &:hover {
        background: #b7b7b7;
      }
    }
    input{
      outline: none;
    }
  }
}

.nombre-archivo-subido{
  padding: 10px 15px;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    font-size: 20px;
    margin-right: 3px;
  }
}