.modal-bioseguridad-higiene{
  .modal-dialog{
    width: 1000px;
    .tabla-verracos-en-grupo-verracos{
      padding: 15px;
    }
    .filtros-tabla-modal-limpieza-silos, .filtros-tabla-modal-limpieza-corrales, .filtros-tabla-modal-otros{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-right: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .nuevo{
        margin: 0;
        padding: 1px 6px;
        margin-left: 0px;
        margin-top: 3px;
        float: left;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .custom-check-w {
        width: 250px;
      }
      .col-sm-6{
        width: 47px;
      }
    }
    .oculta-action{
      .btn-icono{
        display: none;
        svg{
          font-size: 17px;
        }
      }
    }
  }
}

.error-limpieza-silos{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
  }
}
.noerror-limpieza-silos{
  display: none;
}

.tabla-modal-limpieza-silos{
  border-bottom: none;
  padding: 0 16px;
  .buttons-min{
    text-align: right;
    .button-aplicar{
      margin: 0 5px 5px 0px;
    }
  }
}

.botones-footer{
  border-top: 1px solid #d1d1d1;
  clear: both;
  padding-top: 13px;
  padding-right: 13px;
  text-align: right;
  .btn-primary{
    margin-right: 10px;
  }
  .btn-white{
    margin-right: 10px;
  }
}

.submodal{
  .modal-dialog{
    width: 850px;
    .tabla-submodal{
      padding: 15px;
      .filtros-tabla-modal-submodal{
        display: block;
        background: #f1f1f1;
        width: 100%;
        height: 36px;
        clear: both;
        border: 1px solid #d1d1d1;
        margin-bottom: 10px;
      }
    }
  }
}