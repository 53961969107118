.content-plano{
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  .item-plano{
    border: 1px solid #c1c1c1;
    padding: 3px 16px 0px 15px;
    background: rgba(0,0,0,0.06);
    font-size: 14px;
    font-weight: bold;
    color: #555;
    width: 100%;
    min-height: 50px;
    margin-right: 2%;
    display: inline-block;
    .sub-plano{
      .item-plano{
        width: 100%;
        min-height: auto;
        display: inline-block;
        background: rgba(0, 0, 0, 0.05);
        margin-bottom: 7px;
        p{
          padding-right: 10px;
          display: inline-block;
          margin-bottom: 5px;
        }
        .sub-plano{
          .item-plano{
            background: #fff;
            margin-bottom: 7px;
            p{
              padding-right: 10px;
              display: inline-block;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .item-silo {
      background: #6cb7d0 !important;
      min-height: auto!important;
      margin-bottom: 8px;
    }
    .item-estraccion{
      background: #fbc667!important;
      min-height: auto!important;
      margin-bottom: 8px;
    }
    .item-corral{
      background: #fff!important;
      min-height: auto!important;
      margin-bottom: 8px;
    }
    .item-no-animales{
      background: #d1d1d1!important;
      min-height: auto!important;
      margin-bottom: 8px;
    }
    p{
      padding-right: 10px;
      display: block;
      margin-bottom: 5px;
    }
    .plano-desplegable{
      float: right;
      cursor: pointer;
      &:hover{
        opacity: 0.5;
      }
    }
    .cerdito{
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
    svg{
      font-size: 19px;
      vertical-align: sub;
      margin-right: 4px;
    }
  }
}

.item-silo{
  background: #6cb7d0!important;
  min-height: auto!important;
}
.item-estraccion{
  background: #fbc667!important;
  min-height: auto!important;
}
.item-no-animales{
  background: #d1d1d1!important;
  min-height: auto!important;
}

.item-corral{
  background: #fff!important;
  min-height: auto!important;
}
.item-pasillo{
  margin-bottom: 8px;
  min-height: auto!important;
}

.content-plano > div {
  outline: none;
}

.item-plegado{
  .item-plano{
    display: none;
  }
  .sub-plano{
    display: none;
  }
}

.pasillo-desplegable{
  float: right;
  cursor: pointer;
  &:hover{
    opacity: 0.5;
  }
}
