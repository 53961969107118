.titulo-tabla-notificaciones{
  background: #e2e2e1;
  color: #444;
  font-size: 11px;
  padding: 7px 0px;
  text-align: center;
  font-weight: bold;
}

.columns-tabla-notificaciones{
  font-size: 11px;
  padding: 7px 0px;
  text-align: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  &:hover{
    background: rgba(0,0,0,0.02);
  }
}

.notifications{
  position: relative;
  .num-notificaciones-campana{
    font-size: 9px;
    background: #FF6D29;
    border-radius: 50%;
    padding: 0px 4px;
    margin-left: 4px;
    color: #fff;
    vertical-align: text-bottom;
    position: absolute;
    bottom: 4px;
    left: 10px;
  }
}

.col-noti-agenda{
  p{
    font-size: 11px;
    font-weight: bold;
  }
}

.col-noti-avisos-stok{
  p{
    font-size: 11px;
    margin-top: 15px;
    font-weight: bold;
  }
}

.columns-avisos-stock{
  font-size: 11px;
  cursor: pointer;
  margin-bottom: 7px;
  svg{
    font-size: 17px;
    vertical-align: bottom;
    margin-right: 5px;
  }
  /*&:hover{
    color: #c1c1c1;
    svg{
      color: #c1c1c1;
    }
  }*/
}

.campana-noti-off{
  color: #949494;
  svg{
    font-size: 18px;
  }
}
.campana-noti-activa{
  color: #FF6D29;
  svg{
    font-size: 18px;
  }
}

.notificaciones-popover{
  max-height: 450px;
  overflow: auto;
  overflow-x: hidden;
}