/*.mensaje-alerta ~ .modal-backdrop{
  background: none;
}*/

.mensaje-alerta:has(div){
  background: none;
}

.mensaje-alerta{
  .modal-dialog{
    width: 300px;
    position: absolute;
    top: auto !important;
    right: 0;
    bottom: 0;
    margin: 10px;
    transform: initial!important;
  }
  .modal-content{
    background: #41c54b;
    color: #fff;
    box-shadow: none;
    font-size: 13px;
    .modal-body{
      padding: 22px 25px 15px 25px;
    }
  }
}

.error-mensaje-alerta{
  .modal-dialog{
    width: 300px;
    position: absolute;
    top: auto !important;
    right: 0;
    bottom: 0;
    margin: 10px;
    transform: initial!important;
  }
  .modal-content{
    background: #f52424;
    color: #fff;
    box-shadow: none;
    font-size: 13px;
    .modal-body{
      padding: 22px 25px 15px 25px;
    }
  }
}