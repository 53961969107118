@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import "../../assets/scss/variables";

.modal-agenda {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
}


/* Estilos para el componente fullCalendar.io*/
.fc.fc-bootstrap {
  .fc-toolbar.fc-header-toolbar {
    .btn {
      background-color: #999 !important;
      color: #fff !important;
      border: none;
      box-shadow: none !important;
      outline: none !important;
      z-index: 1 !important;

      &:active, &.active,
      &:focus, &.focus,
      &:hover, &.hover {
        background: #222 !important;
        box-shadow: none !important;
        outline: none !important;
        z-index: 1 !important;
      }
    }
  }

  .fc-event-container {
    position: relative;

    .fc-content {
      height: 100%;

      .overlayedContent {
        height: 100%;
        cursor: pointer;
      }
    }

    // TODO Eliminar
    //.dropdown-toggle {
    //  position: absolute;
    //  top: 0;
    //  right: 0;
    //  height: 100% !important;
    //  line-height: 0;
    //  margin-top: 0 !important;
    //  padding: 0;
    //  color: inherit;
    //  background-color: transparent;
    //  border: none;
    //  box-shadow: none;
    //
    //  svg {
    //    font-size: 12px;
    //  }
    //}
  }

  .tooltip {
    z-index: 1;
    opacity: 1;
    font-family: 'opens-sans', sans-serif;

    .tooltip-arrow {
      border-left-color: #fff;
    }
    .tooltip-inner {
      max-width: 100%;
      padding: 0;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      font-size: 14px;

      ul {
        padding: 5px 0;
        margin: 2px 0 0;
        text-align: left;
        list-style: none;

        li > a {
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.42857143;
          color: #333;
          white-space: nowrap;

          &:hover {
            color: #262626;
            text-decoration: none;
            background-color: #f5f5f5;
          }

          span {
            position: relative;
            top: -5px;
          }
          svg {
            font-size: 22px;
            margin-right: 5px;
          }
        }

        .divider {
          height: 2px;
          margin: 9px 0;
          overflow: hidden;
          background-color: #e5e5e5;
        }
      }
    }
  }

  .fc-popover.fc-more-popover.card.card-primary {
    background: white;

    .fc-header.card-header {
      background: #eee;

      .fc-title {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.tooltip[id^="tooltipEventId"] {
  z-index: 1;
}

/* Estilos para la tabla de tareas diarias. */
#tareasDiariasAgendaTable.table-scroll-container {
  max-height: 250px;
  overflow: auto;
  border: 1px solid rgb(238, 238, 238);
}