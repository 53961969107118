@import "../../../assets/scss/variables";

.simple-table {
  font-size: 12px;

  .table-scroll-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;

    .table {
      margin-bottom: 0;
    }
  }

  & > thead > tr > th,
  & > tbody > tr > td {
    height: 30px;
    padding: .1rem .5rem;
    text-align: center;
    vertical-align: middle;
    svg{
      font-size: 17px;
    }
  }
  & > thead > tr {
    & > th {
      color: $white;
      background-color: $header-1-bg;
      border-color: $header-1-bg;
      cursor: pointer;
      user-select: none;
    }
    &:last-child > th {
      background-color: $header-2-bg;
      border-color: $header-2-bg;
    }
  }

  &.table-striped > tbody {
    & > tr:nth-of-type(odd){
      color: $row-odd-color;
      background-color: $row-odd-bg;

      .options-col .dropdown {
        color: $row-odd-color;
        background-color: $row-odd-bg;
      }
    }
    & > tr:nth-of-type(even){
      background-color: $row-even-bg;

      .options-col .dropdown {
        background-color: $row-even-bg;
      }
    }
  }

  & > tbody > tr > td > .barra-color {
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    &.verraco-en-cuarentena {
      background: #edd3a0 !important;
      .options-col .dropdown {
        background-color: #edd3a0 !important;
      }
    }
    &.verraco-en-presente, &.envasado, &.albaran-estado-borrador {
      background: #aacfdc !important;
      .options-col .dropdown {
        background: #aacfdc !important;
      }
    }
    &.verraco-en-activo, &.albaran-estado-realizado {
      background: #c0d2a1 !important;
      .options-col .dropdown {
        background: #c0d2a1 !important;
      }
    }
    &.verraco-en-baja {
      background: #de9ed7 !important;
      .options-col .dropdown {
        background: #de9ed7 !important;
      }
    }
    &.verraco-en-eliminado, &.albaran-estado-cancelado {
      background: #e59f93 !important;
      .options-col .dropdown {
        background: #e59f93 !important;
      }
    }
    &.verraco-en-ningun-estado {
      background: #fff !important;
      .options-col .dropdown {
        background: #fff !important;
      }
    }
    &.analisis-eyaculado-eliminado, &.pool-eliminado, &.envasado-eliminado {
      background: #c4415d !important;
      color: #fff !important;
      .options-col .dropdown {
        background: #c4415d !important;
        color: #fff !important;
      }
    }
    &.analisis-pertenece-pool, &.grupo-admin {
      background: #2538aa !important;
      color: #fff !important;
      .options-col .dropdown {
        background: #2538aa !important;
        color: #fff !important;
      }
    }
    &.lote-recomendado {
      font-weight: 700;
    }
    &.extraccion-sin-analisis{
      background: #e4d841 !important;
      color: #fff !important;
      .options-col .dropdown {
        background: #e4d841 !important;
        color: #fff !important;
      }
    }
  }
  & > tbody > tr {
    & > td {
      vertical-align: middle;
      white-space: nowrap;

      [class|="estado"], .pool-verracos, .lote-recomendado {
        padding: 1px 8px;
        margin-top: 1px;
        display: inline-block;
        border-radius: 5px;
        color: #fff;
      }
      .estado-cuarentena{
        background: #e89c08;
        color: #fff;
        font-weight: bold;
      }
      .estado-presentado{
        background: #1e9fab;
        color: #fff;
        font-weight: bold;
      }
      .estado-activo{
        background: #7ebc12;
        color: #fff;
        font-weight: bold;
      }
      .estado-baja{
        background: #ec45da;
        color: #fff;
        font-weight: bold;
      }
      .estado-eliminado{
        background: #a92a15;
        color: #fff;
        font-weight: bold;
      }
      .pool-verracos {
        background: #2538aa;
        color: #fff;
        font-weight: bold;
      }
      .lote-recomendado {
        background: #FFB694; // #ff6d2980 HEX con opacidad
        color: #212529;
      }
      .cantidad-negativa {
        color: #c4415d;
        font-weight: 700;
      }
    }
    &:hover{
      cursor: pointer;
      background: rgba(0,0,0,0.05)!important;
      user-select: none;
      transition: 0.3s;
      .dropdown{
        background: #f2f2f2!important;
        transition: 0.3s;
      }
    }
  }

  .options-col {
    min-width: 25px;
    .dropdown {
      z-index: 4;
      position: absolute;
      right: 15px;
      transform: translateY(-10px);
      width: 25px;
      background: #000;

      .dropdown-toggle {
        height: auto !important;
        margin-top: 0 !important;
        padding: 0;
        color: inherit;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
      &.open > .dropdown-toggle {
        &.focus, &:focus, &:hover {
          color: inherit;
          background-color: transparent;
        }
      }

      .dropdown-menu {
        right: 25px;

        .divider {
          height: 2px;
        }
        & > li > a > {
          svg {
            font-size: 22px;
            margin-right: 5px;
          }
          span {
            position: relative;
            top: -5px;
          }
        }
      }
      .btn-default{
        &:hover{
          color: #c1c1c1!important;
        }
      }
    }
  }

  .leyenda {
    padding-top: 15px;

    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        margin-right: 15px;

        div {
          width: 10px;
          height: 10px;
          margin-right: 5px;
        }
      }
    }
  }


  /* TABLE FOOTER - PAGINATION */
  .pagination>li>a, .pagination>li>span{
    padding: 2px 7px;
  }
  .content-pagination {
    & > .col-auto {
      flex: 1 0 auto;
    }

    .pagination-info {
      color: black;
      padding-top: 10px;
    }

    .pagination{
      display: inline;
      margin: 0px 17px;
    }
    .pagination>.active>a{
      background-color: #eee;
      border-color: #ddd;
      color: #888;
      &:focus{
        background-color: #eee;
        border-color: #ddd;
      }
      &:hover{
        background-color: #eee;
        border-color: #ddd;
      }
    }
    .pagination>.active>span{
      background-color: #eee;
      border-color: #ddd;
      &:focus{
        background-color: #eee;
        border-color: #ddd;
      }
      &:hover{
        background-color: #eee;
        border-color: #ddd;
      }
    }
    .pagination>li>a{
      color: #888;
    }
    .pagination>li>span{
      color: #888;
    }

    .select-total-registros{
      margin-top: 4px;
      .form-group{
        margin-bottom: 0;
        .control-label{
          display: inline-block;
          float: left;
          margin-top: 5px;
          margin-right: 11px;
          border-left: 1px solid #d1d1d1;
          padding-left: 11px;
        }
        & > div {
          float: left;
        }

        #candidadRegistros > div {

          &:first-of-type, &:last-of-type {
            width: 105px;
          }
          &:first-of-type {
            height: 29px;
            min-height: 29px;

            & > div:last-child {
              span {
                width: 2px;
              }
              div {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  /* TABLE FOOTER - PAGINATION */
}

/*popover - grupo verraco en simple table*/
.popover-simple-table{
  .popover-content{
    min-width: 140px;
    max-height: 230px;
    overflow-y: scroll;
  }
}
.popover-xl {
  max-width: 500px;
}

.tablaTotales {
  .has-error {
    color: $danger;
    font-weight: 700;
    &:not(.no-bg) {
      border: 1px solid $danger;
      background-color: $white;
    }
  }
  .has-info {
    color: $info;
    font-weight: 700;
    &:not(.no-bg) {
      border: 1px solid $info;
      background-color: $white;
    }
  }
}

.font-gesipor-desc,
.font-gesipor-asc{
  display: inline-block;
  vertical-align: middle;
  font-size: 8px;
  margin-left: 5px;
}

.ordenable{
  &:hover{
    color: #eee;
  }
}


.fila-seleccionada-foso{
  border: 3px solid #565656!important;
}

.checkbox-col{
  position: relative;
  width: 50px;
}

.custom-check-w {
  width: 50px;
}
