.totales-verracos{
  .form-group {
    margin-top: 5px;
    margin-bottom: 5px;
    .InputFromTo input{
      padding: 1px 5px;
    }
    .InputFromTo {
      .clear-fecha {
        padding: 0;
      }
      .InputFromTo-to{
        input{
          padding: 1px 5px;
        }
        .clear-fecha {
          padding: 0;
        }
      }
    }
  }
}