@import "../../../assets/scss/variables";

.btn {
  color: $darkGrey;
  font-weight: 100;
  font-size: 13px;
  border: 1px solid $lightGrey;
  padding: 2px 7px;
  &:hover, &:active {
    color: $white !important;
    background: $secondary;
    border-color: $secondary;
  }
  &:focus{
    color: $white;
    background: $secondary;
    border-color: $secondary;
  }
  & > svg {
    vertical-align: text-bottom;
  }

  & ~ .btn  {
    margin-left: 3px;
  }
  & ~ .btn.pull-right  {
    margin-right: 3px;
  }

  &.btn-input-style {
    margin-top: 23px;
    width: 100%;
    &.no-margin {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  &.btn-width-auto {
    width: auto;
  }

  &.no-border {
    border: none;
  }

  &.no-bg {
    &:hover, &.btn:active {
      color: #333 !important;
      background-color: rgba(0,0,0,0);
    }
  }

  &.full-size {
    width: 100%;
    padding: 0;
  }

  &.text-left {
    text-align: left;
  }
}

.botones-pie-form {
  text-align: right;
  button{
    margin: 10px 3px;
  }
}