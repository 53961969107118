@import "../../../assets/scss/variables";

input[type="text"], input[type="password"] {
  & ~ .input-group-addon > svg {
    font-size: 17px;
  }
  & ~ .input-group-addon ~ .help-block > span,
  & ~ .help-block > span {
    vertical-align: super;
  }
}

.help-block {
  height: 13px;
  display: inline-block;
  vertical-align: sub;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  svg{
    font-size: 14px;
  }
}

.negative-input input {
  color: $danger;
  font-weight: 700;
}

// Utiliado en el bloque de acrosomas y formas anormales para mostrar valores en inputs como si fueran labels
input.form-control-disabled {
  width: 100%;
  border: none;
  padding: 0;
  background: none;
}

.form-control.inline {
  display: inline;
  width: 50px;
}