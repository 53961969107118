.form-group{
  .css-10nd86i{
    .css-vj8t7z{
      min-height: 30px;
      .css-1wy0on6{
        .css-1ep9fjw{
          padding: 1px;
        }
      }
    }
  }
}

.form-group{
  .css-10nd86i{
    .css-2o5izw{
      min-height: 30px;
      .css-1wy0on6{
        .css-1uq0kb5{
          padding: 1px;
        }
      }
    }
  }
}

.form-group{
  .css-1sontr1{
    .css-13vsyks{
      min-height: 30px;
      .css-1wy0on6{
        .css-1ep9fjw{
          padding: 1px;
        }
      }
    }
  }
}

.select-inline {
  min-width: 125px;
  display: inline-block;
}

.has-error {
  .select-control > div {
    border-color: #a94442;
  }
}