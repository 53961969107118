@import "../../../assets/scss/variables";

input[type="checkbox"] {
  display: none;
}

.form-group .checkbox {
  margin-top: 0;
  margin-bottom: 0;

  & > label {
    height: auto;
    margin-bottom: 0;
    padding-left: 0;
  }
}


/* CHECKBOX TICK */
.checkbox, .checkbox-inline {
  .checkmark {
    position: absolute;
    left: 0;
    top: 0px;
    min-height: auto;
    height: 12px;
    width: 12px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    padding-left: 0;
  }

  .checkmark:after {
    left: 2px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid $primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

tbody > tr > td.checkbox-col {
  text-align: left;

  [class*="col-sm"] {
    padding-right: 15px !important;
    padding-left: 15px !important;

    .form-group {
      margin-bottom: 10px !important;
      .checkmark {
        top: 0px;
        left: 12px;
      }
    }
  }

}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}



.checkbox-inline{
  display: inline-block !important;
  padding-left: 12px;
}
/* FIN CHECKBOX TICK */

/* CHECKBOX BOLA */
.onoffswitch-label {
  display: block;
  overflow: hidden;
  position: relative;
  height: 17px;
  width: 36px;
  padding: 0;
  line-height: 30px;
  border: 2px solid #999999;
  border-radius: 30px;
  background-color: #999;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
}
.onoffswitch-label:after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  position: absolute;
  top: -2px;
  bottom: 0;
  right: 17px;
  margin: 0px;
  background: #FFFFFF;
  border: 2px solid #999999;
  border-radius: 30px;
  transition: all 0.3s ease-in 0s;
}
.checkbox-inline-activo input:checked ~ .onoffswitch-label {
  background-color: #FF6D29;
}
.checkbox-inline-activo input:checked ~ .onoffswitch-label, .checkbox-inline-activo input:checked ~ .onoffswitch-label:after {
  border-color: #FF6D29;
}
.checkbox-inline-activo input:checked ~ .onoffswitch-label:after {
  right: -2px;
}
/* FIN CHECKBOX BOLA */

.labelInput{
  padding-left: 20px;
  display: inline;
  font-size: 11px;
  color: #868686;
}

.checkDisabled{
  border: 2px solid #dcdbdb;
  background-color: #dcdbdb;
  cursor: no-drop;
}
.checkDisabled:after{
  border: 2px solid #dcdbdb;
  background: #efefef;
}

.checkbox{
  label{
    min-height: 17px;
  }
}
.radio{
  label{
    min-height: 17px;
  }
}

