.imagenUsuarios{
  padding: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background: #fafafa;
  position: relative;
  .custom-file-label{
    position: absolute;
    top: 40px;
    /*left: 32px;*/
    cursor: pointer;
    span{
      display: block;
    }
    svg{
      font-size: 45px;
    }
  }
  .content-dropzone{
    position: relative;
    .dropzone{
      text-align: center;
      outline: none;
      width: 0;
      height: 0;
      border-right: 23px solid rgba(255, 142, 0, 0.7);
      border-top: 23px solid transparent;
      border-left: 21px solid transparent;
      border-bottom: 23px solid rgba(255, 142, 0, 0.7);
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      svg{
        font-size: 17px;
        color: #fff;
      }
      &:hover{
        border-right: 23px solid rgba(0,0,0,0.7);
        border-bottom: 23px solid rgba(0,0,0,0.7);
        svg{
          opacity: 0.8;
        }
      }
    }
    .imagen-perfil-usuarios{
      img{
        width: 100%;
      }
    }
    .error-upload-img-oculto{
      display: none;
    }
    .error-upload-img-visible{
      background: #d63737;
      display: inline-block;
      color: #fff;
      padding: 5px 22px;
      border-radius: 15px;
      position: absolute;
      top: 13px;
      right: 0;
    }
    .eliminar-imagen-usuarios{
      position: absolute;
      background: rgba(0,0,0,0.3);
      color: #fff;
      border-radius: 50%;
      padding: 5px 6px 2px 6px;
      font-size: 10px;
      right: 0;
      top: 0;
      cursor: pointer;
      svg{
        font-size: 16px;
      }
      &:hover{
        background: #222;
      }
    }
  }
}

.modal-usuarios{
  .modal-dialog{
    width: 1035px;
  }
  .checkbox-col {
    width: 60px;
  }
}

.hidden{
  display: none;
}

.mensaje-error-usuarios{
  margin-left: 10px;
  margin-bottom: 8px;
  display: block;
  color: #a50000;
  svg{
    vertical-align: bottom;
    font-size: 15px;
    margin-right: 4px;
  }
}

.modal-cambiar-pass{
  .modal-dialog{
    width: 313px;
  }
  .checkbox-col {
    width: 60px;
  }
}

.modal-grupo-usuarios{
  .checkbox-col {
    width: 60px;
  }
}

.modal-listado-centro{
  .checkbox-col {
    width: 60px;
  }
}

.modal-token-magavision{
  .modal-dialog{
    width: 313px;
  }
}
