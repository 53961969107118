@import "../../../assets/scss/variables";

.form-group-subtitle {
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 5px;
   margin-top: 15px;

  &.form-group-subtitle-small {
    font-size: 11px;
    font-weight: 700;
    margin-top: 2px;
    color: $primary;
  }
}

