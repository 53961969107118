@font-face {
  font-family: 'opens-sans';
  font-style: normal;
  font-weight: 300;
  src: url(font/OpenSans-Light.woff); }

@font-face {
  font-family: 'opens-sans';
  font-style: normal;
  font-weight: 400;
  src: url(font/OpenSans.woff); }

@font-face {
  font-family: 'opens-sans';
  font-style: bold;
  font-weight: 600;
  src: url(font/OpenSans-Bold.woff); }

/*@font-face {
    font-family: 'opens-sans';
    font-style: bold;
    font-weight: 800;
    src: url(OpenSans-Bold.woff);
}*/
@font-face {
  font-family: 'font-gesipor';
  src: url("font/font-gesipor.eot?dskygn");
  src: url("font/font-gesipor.eot?dskygn#iefix") format("embedded-opentype"), url("font/font-gesipor.ttf?dskygn") format("truetype"), url("font/font-gesipor.woff?dskygn") format("woff"), url("font/font-gesipor.svg?dskygn#font-gesipor") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="font-gesipor"], [class*=" font-gesipor"] {
  /* use !important to prevent issues with browser extensions that change font */
  font-family: 'font-gesipor' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.font-gesipor-cerdo:before {
  content: "\e900"; }

.font-gesipor-orderDesc:before {
  content: "\e901"; }

.font-gesipor-orderAsc:before {
  content: "\e902"; }

.font-gesipor-asc:before {
  content: "\e903"; }

.font-gesipor-desc:before {
  content: "\e904"; }

h2 {
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 23px !important; }

h3 {
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 17px !important; }

label {
  font-weight: 600; }

body {
  font-family: 'opens-sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  background-color: #fff; }

.input-group-addon {
  padding: 4px 12px; }
  .input-group-addon svg {
    vertical-align: middle; }

footer {
  border-top: 1px solid #eee;
  margin-top: 40px;
  padding-top: 13px;
  margin-bottom: 25px; }

.full-height {
  height: 100%; }

.white-space-nowrap {
  white-space: nowrap; }

.help-block {
  color: #dc3545;
  font-weight: bold; }
  .help-block.white {
    color: #fff; }
  .help-block.light {
    color: #c1c1c1;
    font-style: italic;
    font-size: 10px;
    font-weight: normal; }

.text-info {
  color: #2538aa; }
  .text-info svg {
    vertical-align: bottom;
    margin-right: 5px; }

.bold {
  font-weight: 700; }

.border-top {
  border-top: 1px solid #eee; }
  .border-top.merge-top-border {
    margin-top: -1px; }

.border-right {
  border-right: 1px solid #eee; }
  .border-right.merge-right-border {
    margin-right: -1px; }

.border-bottom {
  border-bottom: 1px solid #eee; }
  .border-bottom.merge-bottom-border {
    margin-bottom: -1px; }

.border-left {
  border-left: 1px solid #eee; }
  .border-left.merge-left-border {
    margin-left: -1px; }

.inline {
  display: inline; }

.inline-block {
  display: inline-block; }

.m-t-0 {
  margin-top: 0px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.m-a-0 {
  margin: 0px !important; }

.p-a-0 {
  padding: 0px !important; }

.m-a-5 {
  margin: 5px !important; }

.p-a-5 {
  padding: 5px !important; }

.m-a-10 {
  margin: 10px !important; }

.p-a-10 {
  padding: 10px !important; }

.m-a-15 {
  margin: 15px !important; }

.p-a-15 {
  padding: 15px !important; }

.m-a-20 {
  margin: 20px !important; }

.p-a-20 {
  padding: 20px !important; }

.m-a-25 {
  margin: 25px !important; }

.p-a-25 {
  padding: 25px !important; }

.m-a-30 {
  margin: 30px !important; }

.p-a-30 {
  padding: 30px !important; }

.m-a-35 {
  margin: 35px !important; }

.p-a-35 {
  padding: 35px !important; }

.m-a-40 {
  margin: 40px !important; }

.p-a-40 {
  padding: 40px !important; }

.m-a-45 {
  margin: 45px !important; }

.p-a-45 {
  padding: 45px !important; }

.m-a-50 {
  margin: 50px !important; }

.p-a-50 {
  padding: 50px !important; }

.m-a-75 {
  margin: 75px !important; }

.p-a-75 {
  padding: 75px !important; }

.m-a-100 {
  margin: 100px !important; }

.p-a-100 {
  padding: 100px !important; }

/* Links */
a {
  color: #FF6D29;
  text-decoration: none; }
  a:hover, a:focus {
    color: #dc4600;
    text-decoration: underline; }

a.btn.btn-link {
  border-radius: 4px; }
  a.btn.btn-link:hover, a.btn.btn-link:active, a.btn.btn-link:focus {
    text-decoration: none; }

/* Modales: mostrar multiples modales aumentando la posicion top secuencialmente. */
.modal-dialog {
  margin: 10px auto; }

div[role="dialog"] ~ div[role="dialog"] .modal-dialog {
  transform: translate(0, 0%);
  top: 10px; }

.modal-header .close {
  outline: none; }

/* Custom Layouts */
.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .row-flex .col-flex {
    flex-basis: 0;
    flex-grow: 1;
    min-height: 1px;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .row-flex .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    padding-right: 15px;
    padding-left: 15px; }

.row-collapsed {
  margin-right: -5px;
  margin-left: -5px; }
  .row-collapsed > [class*="col"] {
    padding-right: 5px;
    padding-left: 5px; }

.row-collapsed-full {
  margin-right: 0;
  margin-left: 0; }
  .row-collapsed-full > [class*="col"] {
    padding-right: 0;
    padding-left: 0; }

.form-group {
  margin-bottom: 10px; }
  .form-group .form-control {
    height: 31px;
    /* margin-bottom: 7px; */ }

textarea.form-control {
  height: 93px !important; }

.dropzone.custom-file-label {
  position: relative;
  border: 1px solid #d1d1d1;
  background: #fff;
  display: block;
  width: 100%;
  padding: 3px 15px;
  cursor: pointer;
  border-radius: 4px;
  height: 25px; }
  .dropzone.custom-file-label div {
    width: 100%; }
    .dropzone.custom-file-label div .info-input-file {
      display: block;
      height: 100%;
      font-size: 11px;
      font-weight: 400; }
    .dropzone.custom-file-label div .explorar-input-file {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 70px;
      padding: 3px 0;
      text-align: center;
      background: #888;
      color: #fff; }

.form-control::placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

.form-control:-ms-input-placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

.form-control::-ms-input-placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

.form-control:-moz-placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

.form-control::-moz-placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

.form-control::-webkit-input-placeholder {
  color: #999 !important;
  opacity: 0.4 !important; }

/* TABLA DE FILTROS */
.tabla-filtros {
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  width: 100%;
  padding: 0; }
  .tabla-filtros .custom-form > div:not(.aplicar-tabla-filtros) {
    display: none; }
  .tabla-filtros .custom-form > .aplicar-tabla-filtros > .filtros-anadidos > span:first-child {
    margin-top: 7px; }
  .tabla-filtros .control-label {
    margin-left: 3px;
    margin-bottom: 1px; }
  .tabla-filtros .aplicar-tabla-filtros {
    justify-content: space-between !important;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 5px;
    clear: both; }
    .tabla-filtros .aplicar-tabla-filtros .button-aplicar {
      width: 90px;
      padding: 1px 2px 3px 2px; }
      .tabla-filtros .aplicar-tabla-filtros .button-aplicar span {
        font-size: 13px;
        margin-left: 3px; }
      .tabla-filtros .aplicar-tabla-filtros .button-aplicar svg {
        font-size: 17px; }
    .tabla-filtros .aplicar-tabla-filtros .filtros-anadidos .badge-dark {
      color: #fff;
      background-color: #FF6D29;
      margin-left: 6px;
      padding: 4px 8px;
      font-size: 10px;
      border-radius: 8px;
      margin-bottom: 7px;
      display: inline-block;
      margin-top: 0; }
      .tabla-filtros .aplicar-tabla-filtros .filtros-anadidos .badge-dark .quitar-filtro {
        background: #fff;
        border: none;
        color: #000;
        border-radius: 50%;
        padding: 0px 4px 0px 4px;
        margin-left: 5px;
        outline: none;
        font-size: 9px;
        vertical-align: top;
        margin-top: 1px; }
        .tabla-filtros .aplicar-tabla-filtros .filtros-anadidos .badge-dark .quitar-filtro:hover {
          opacity: 0.8; }

.boton-aviso {
  color: #a94442;
  border: 1px solid #a94442 !important;
  margin-left: 10px;
  font-size: 14px;
  background: #fff !important;
  padding: 0px 5px !important;
  font-size: 12px; }
  .boton-aviso:hover {
    color: #fff !important;
    border-color: #a94442 !important;
    background: #a94442 !important; }
  .boton-aviso:active {
    color: #a94442;
    border-color: #a94442 !important; }
  .boton-aviso:focus {
    color: #a94442;
    border-color: #a94442 !important; }
  .boton-aviso svg {
    margin-right: 5px;
    vertical-align: middle !important;
    font-size: 15px !important; }

.open-filters {
  position: relative;
  bottom: -2px;
  background: #fff; }
  .open-filters.tabla-filtros {
    padding: 7px 0 0 0; }
  .open-filters .custom-form > div:not(.aplicar-tabla-filtros) {
    display: block; }
  .open-filters .custom-form > .aplicar-tabla-filtros > .filtros-anadidos > span:first-child {
    margin-top: 0; }

.acciones-tabla-filtros {
  background: #eee;
  border: 1px solid #dcdcdc;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 5px;
  height: 30px; }
  .acciones-tabla-filtros [class*="col-sm"] {
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .acciones-tabla-filtros .form-group {
    margin-bottom: 15px !important; }
  .acciones-tabla-filtros .buttons-flex {
    display: flex; }
    .acciones-tabla-filtros .buttons-flex .button-group {
      padding-top: 3px; }
    .acciones-tabla-filtros .buttons-flex .block-options .barra-buttons-min {
      border-left: 1px solid #d1d1d1;
      display: inline-block;
      height: 18px;
      padding: 5px;
      margin-left: 7px;
      vertical-align: middle; }
    .acciones-tabla-filtros .buttons-flex .oculta-action {
      display: none; }
    .acciones-tabla-filtros .buttons-flex .btn-icono {
      border: none;
      font-size: 12px;
      background: #999;
      color: #fff;
      padding: 1px 10px;
      margin-left: 8px;
      margin-top: 2px; }
      .acciones-tabla-filtros .buttons-flex .btn-icono:hover {
        background: #000; }
      .acciones-tabla-filtros .buttons-flex .btn-icono:focus {
        background: #000; }
      .acciones-tabla-filtros .buttons-flex .btn-icono:active {
        background: #000; }
    .acciones-tabla-filtros .buttons-flex button {
      height: auto !important;
      background: none;
      vertical-align: initial;
      padding: 2px; }
      .acciones-tabla-filtros .buttons-flex button:active {
        box-shadow: none;
        outline: none; }
      .acciones-tabla-filtros .buttons-flex button:focus {
        outline: none; }
      .acciones-tabla-filtros .buttons-flex button svg {
        vertical-align: middle;
        font-size: 15px; }
    .acciones-tabla-filtros .buttons-flex .nuevo {
      background: #FF6D29;
      margin-left: 6px;
      font-size: 12px;
      padding: 1px 6px; }
      .acciones-tabla-filtros .buttons-flex .nuevo:hover {
        background: #222; }
      .acciones-tabla-filtros .buttons-flex .nuevo svg {
        font-size: 15px; }
      form .acciones-tabla-filtros .buttons-flex .nuevo {
        margin: 0;
        padding: 1px 6px;
        margin-left: 0px;
        float: left; }
        form .acciones-tabla-filtros .buttons-flex .nuevo svg {
          font-size: 16px;
          vertical-align: bottom; }
  .acciones-tabla-filtros .content-button-filtro {
    padding: 0; }
    .acciones-tabla-filtros .content-button-filtro .button-filtro {
      text-align: right; }
      .acciones-tabla-filtros .content-button-filtro .button-filtro .filters-desp {
        color: #222;
        border-radius: 0;
        padding: 5px;
        transition: 0.2s;
        border: none; }
        .acciones-tabla-filtros .content-button-filtro .button-filtro .filters-desp:hover {
          background: none;
          color: #FF6D29 !important; }
        .acciones-tabla-filtros .content-button-filtro .button-filtro .filters-desp svg {
          vertical-align: top;
          font-size: 18px; }
        .acciones-tabla-filtros .content-button-filtro .button-filtro .filters-desp:focus {
          outline: none;
          box-shadow: none;
          color: #FF6D29; }
        .acciones-tabla-filtros .content-button-filtro .button-filtro .filters-desp:active {
          outline: none;
          box-shadow: none;
          color: #FF6D29; }
      .acciones-tabla-filtros .content-button-filtro .button-filtro .opener-open {
        background: #fff !important;
        border-right: 1px solid #ddd !important;
        transition: 0.2s; }
        .acciones-tabla-filtros .content-button-filtro .button-filtro .opener-open svg {
          transform: rotate(180deg); }

.custom-form.col, .custom-form > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.button-aplicar {
  margin-top: 17px;
  width: 90px;
  padding: 6px 3px 5px 2px; }
  .button-aplicar span {
    font-size: 13px;
    margin-left: 3px; }
  .button-aplicar svg {
    font-size: 17px; }

/* FIN TABLA DE FILTROS */
/* MODALES */
.modal-backdrop {
  z-index: 1050; }

.modal-header {
  padding: 8px 15px; }

.modal-footer {
  padding: 8px 15px; }

@media (min-width: 992px) {
  .xl {
    width: 1000px; } }

.full-screen {
  width: calc(100% - 20px); }

.quitar-filtro {
  background: #fff;
  border: none;
  color: #000;
  border-radius: 50%;
  padding: 0px 4px 0px 4px;
  margin-left: 5px;
  outline: none;
  font-size: 9px;
  vertical-align: top;
  margin-top: 1px; }
  .quitar-filtro:hover {
    opacity: 0.8; }

.treeview {
  border: 1px solid #e5e5e5;
  padding: 5px; }
  .treeview ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
  .treeview .form-group {
    margin-bottom: 0; }
    .treeview .form-group .checkbox-inline {
      padding-left: 10px; }
      .treeview .form-group .checkbox-inline .onoffswitch-label {
        margin-bottom: 0; }
  .treeview .treeview-header {
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: 5px; }
  .treeview .treeview-body > div > ul > li > .row > div:first-child > ul > li > .row > div:first-child > span {
    padding-left: 5px; }
  .treeview .treeview-body > div > ul ul > li > .row > div:first-child > ul > li > .row > div:first-child > span {
    padding-left: 20px; }
  .treeview .treeview-body > div > ul ul > li > .row ul > li > .row > div:first-child > ul > li > .row > div:first-child > span {
    padding-left: 35px; }
  .treeview .treeview-body > div > ul > li {
    margin-bottom: 5px; }
    .treeview .treeview-body > div > ul > li > .row > div > ul > li {
      border-bottom: 1px dashed #e5e5e5;
      margin-bottom: 5px; }
      .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row {
        margin-bottom: 2px; }
      .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div > ul > li:not(:last-child) {
        margin-bottom: 5px; }
        .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div > ul > li:not(:last-child) > .row {
          margin-bottom: 2px; }
        .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div > ul > li:not(:last-child) > .row > div:first-child > ul > li {
          border-bottom: 1px dashed #e5e5e5; }
          .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div > ul > li:not(:last-child) > .row > div:first-child > ul > li > .row {
            margin-bottom: 2px; }
      .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div:first-child:not(:last-child) > ul > li {
        border-bottom: 1px dashed #e5e5e5; }
        .treeview .treeview-body > div > ul > li > .row > div > ul > li > .row > div:first-child:not(:last-child) > ul > li > .row {
          margin-bottom: 2px; }

.document-viewer {
  width: 100%;
  height: 600px;
  margin-top: 10px; }
