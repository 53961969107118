.help-block{
  color: #a94442;
  svg{
    font-size: 16px;
  }
  .texto-alerta-entrada-mercancia{
    color: #a94442;
    vertical-align: top;
    margin-left: 5px;
  }
}

.modal-producto-entrada-mercancia{
  .modal-dialog{
    width: 940px;
  }
}