.check-activo-conectividad{
  margin-top: 20px;
}

.campo-ip{
  padding-right: 0px;
}

.campo-puerto-conectividad{
  padding-left: 8px;
  padding-top: 10px;
  border-right: 1px solid #d1d1d1;
}

.campo-nombre{
  padding-top: 10px;
}
.campo-contrasena{
  padding-top: 10px;
}

.error-form-conectividad {
  color: #a94442;
  svg{
    vertical-align: bottom;
    font-size: 14px;
  }
}

.check-habilitar-conectividad{
  margin-top: 10px;
}

.probar-conexion-vpn{
  padding: 2px 10px;
  margin-top: 10px;
  height: 25px;
}
