.main-container .login-container,
.main-container .reset-password-container {
  display: inline-block;
  max-width: 420px;
  margin: 20px 0 0;
  padding: 0 20px 20px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  background: #fff;
  border: 1px solid #d1d1d1;

  & > .page-title {
    margin-top: 20px;
    color: #222;
    font-size: 24px;
  }

  & > p {
    font-size: 13px;
  }

  & form a:not(.btn) {
    text-decoration: underline;
  }
}

.main-container .renovar-licencia-container {
  display: block;
  max-width: 600px;
  margin: 20px 0 0;
  padding: 20px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  background: #fff;
}

.link-volver-login {
  color: #666;
  font-weight: 100;
  font-size: 13px;
  border: 1px solid #ddd;
  padding: 2px 7px;
  margin-right: 7px;
  border-radius: 3px;
  &:hover{
    text-decoration: none;
  }
}