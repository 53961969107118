.ver-pedido-albaran{
  margin-top: 22px;
  padding-right: 0;
  button{
    width: 100%;
    height: 31px;
    padding: 2px 1px;
    svg{
      font-size: 13px;
      margin-right: 3px;
      vertical-align: sub;
    }
  }
}

.albaran-estado-borrador{
  background-color: rgb(170, 207, 220)!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.albaran-estado-cancelado{
  background-color: rgb(229, 159, 147)!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.albaran-estado-realizado{
  background-color: rgb(192, 210, 161)!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.modal-albaran-venta{
  .modal-dialog{
    width: 1020px;
  }
}

.resumen-totales{
  background: #b8b6b4;
  color: #fff;
}