.error-linea-pedido{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
    font-size: 17px;
  }
}

.estadopedido-borrador{
  background-color: #9eccf3!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.estadopedido-cancelado{
  background-color: #f98f8f!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}
.estadopedido-confirmado{
  background-color: #efd038!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.estadopedido-realizado{
  background-color: #74de7c!important;
  padding: 2px 9px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.dias-semana{
  .col-sm-2{
    width: 14.25%
  }
}

.info-albaran{
  background: #a5d9f7;
  padding: 5px 17px;
  span{
    margin-right: 15px;
  }
}

.error-linea-pedido{
  display: inline-block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  margin-left: 10px;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
    font-size: 17px;
  }
}
