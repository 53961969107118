.resultado-importar-datos{
  background: #eee;
  margin: 10px;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  padding: 11px;
  min-height: 200px;
  ul{
    padding: 5px;
    li{
      list-style: none;
      padding-bottom: 2px;
    }
    .mensaje-no-inseta{
      border-bottom: 1px solid #d1d1d1;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}