.modal-anadir-verraco-o-pool{
  .modal-dialog{
    width: 940px;
  }
}

.help-block{
  color: #ad0e0e;
  svg{
    font-size: 16px;
    color: #ad0e0e;
  }
  .texto-alerta-validar-materias-primas{
    color: #ad0e0e;
    vertical-align: top;
    margin-left: 5px;
  }
}