@import "../../../assets/scss/variables";

.loader-container {
  position: relative;
  bottom: -5px;
  display: inline-block;
  opacity: .5;

  .loader-main {
    svg {
      font-size: 20px;
      color: $black;

      -webkit-animation: spin 1000ms infinite linear;
      animation: spin 1000ms infinite linear;
    }
  }

  .btn.btn-icono > & {
    height: 24px;
    bottom: 0;
    vertical-align: middle;
    overflow: hidden;
    svg {
      font-size: 24px;
    }
  }
  .modal .btn.btn-icono > &,
  .form-analisis-extracciones .btn.btn-icono > &,
  .btn.btn-icono.btn-sm > & {
    height: 14px;
    bottom: 2px;
    svg {
      font-size: 16px
    }
  }

  .btn:not(.btn-icono) > & {
    position: absolute;
    left: 10px;
    bottom: -2px;
    display: block;
    opacity: .5;
  }
  [class*="col"] > .btn:not(.btn-icono) > & {
    left: 25px;
  }

  .btn-primary > & > .loader-main svg {
    color: #fff;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}