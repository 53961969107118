.form-producto{
  .check-producto{
    padding: 25px 0 0 0px;
  }
  .check-producto-fecha-caducidad{
    padding: 25px 0 0 0px;
  }
  .mostrar-eviar-aviso{
    display: block;
  }
  .no-mostrar-eviar-aviso{
    display: none;
  }
}

.mostrar-enviar-aviso{
  padding: 0;
  padding-right: 16px;
}