.form-carga-index{
  .modal-dialog{
    width: 520px;
    #idCentro{
      margin: 0 15px;
    }
    .form-group{
      margin-top: 15px;
    }
  }
}

.archivos-carga-index{
  border: 1px solid #d1d1d1;
  margin: 0 15px;
  outline: none;
  cursor: pointer;
  .custom-file-label{
    background: #eee;
    label {
      background: #d1d1d1;
      margin: 0;
      padding: 10px;
      outline: none;
      cursor: pointer;
      &:hover {
        background: #b7b7b7;
      }
    }
    input{
      outline: none;
    }
  }
}

.nombre-archivo-subido{
  padding: 10px 15px;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    font-size: 20px;
    margin-right: 3px;
  }
}

.resultado-verracos-index{
  background: #eee;
  margin: 10px;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  padding: 11px;
  height: 300px;
  overflow: auto;
  .index-insertado{
    color: #10a210;
  }
  .index-no-insertado{
    color: #f50000;
  }
}

.index-fallido {
  margin: 15px;
  color: #f50000;
}