.tab-ficha-verraco{
  .nav-tabs{
    margin-bottom: 30px;
    .active{
      a{
        color: #FF6D29;
        border-bottom: 3px solid;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        &:focus, &:hover, &:active, &:visited{
          color: #FF6D29;
          border-bottom: 3px solid;
          border-top: 3px solid transparent;
          border-right: none;
          border-left: none;
        }
      }
    }
    a{
      color: #666;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-right: none;
      border-left: none;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      &:focus, &:active, &:visited{
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
      &:hover{
        color: #FF6D29;
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
    }
  }
  .imagenVerraco{
    padding: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background: #fafafa;
    position: relative;
    .custom-file-label{
      position: absolute;
      top: 40px;
      /*left: 32px;*/
      cursor: pointer;
      span{
        display: block;
      }
      svg{
        font-size: 45px;
      }
    }
    .content-dropzone{
      position: relative;
      .dropzone{
        text-align: center;
        outline: none;
        width: 0;
        height: 0;
        border-right: 23px solid rgba(255, 142, 0, 0.7);
        border-top: 23px solid transparent;
        border-left: 21px solid transparent;
        border-bottom: 23px solid rgba(255, 142, 0, 0.7);
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        svg{
          font-size: 17px;
          color: #fff;
        }
        &:hover{
          border-right: 23px solid rgba(0,0,0,0.7);
          border-bottom: 23px solid rgba(0,0,0,0.7);
          svg{
            opacity: 0.8;
          }
        }
      }
      .imagen-perfil-verraco{
        img{
          width: 100%;
        }
      }
      .error-upload-img-oculto{
        display: none;
      }
      .error-upload-img-visible{
        background: #d63737;
        display: inline-block;
        color: #fff;
        padding: 5px 22px;
        border-radius: 15px;
        position: absolute;
        top: 13px;
        right: 0;
      }
      .eliminar-imagen-verraco{
        position: absolute;
        background: rgba(0,0,0,0.3);
        color: #fff;
        border-radius: 50%;
        padding: 5px 6px 2px 6px;
        font-size: 10px;
        right: 0;
        top: 0;
        cursor: pointer;
        svg{
          font-size: 16px;
        }
        &:hover{
          background: #222;
        }
      }
    }
  }
  .archivosVerraco{
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background: #fafafa;
    position: relative;
    .custom-file-label{
      position: relative;
      margin-top: 10px;
      border: 1px solid #d1d1d1;
      background: #fff;
      display: block;
      width: 100%;
      padding: 3px 15px;
      cursor: pointer;
      .explorar-input-file{
        background: #888;
        padding: 3px 30px;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    ul{
      padding: 0;
      li{
        list-style: none;
        background: #fff;
        border: 1px solid #d1d1d1;
        margin-top: 10px;
        position: relative;
        p{
          display: inline-block;
          margin: 0px 7px;
          vertical-align: middle;
          max-width: 92%;
          overflow: hidden;
        }
        svg{
          vertical-align: middle;
          font-size: 16px;
        }
        .borrar{
          position: absolute;
          right: 9px;
          top: 4px;
          cursor: pointer;
          &:hover{
            color: #c1c1c1;
          }
        }
        .descargar-adjunto-verraco{
          cursor: pointer;
          display: block;
          padding: 6px 16px;
          &:hover{
            p, svg{
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.form-nuevo-verraco{
  .form-group {
    margin-bottom: 15px;
    height: 55px;
    .help-block{
      font-size: 10px;
      .MuiSvgIcon-root-1{
        font-size: 15px;
      }
    }
  }
  .campo-visible{
    display: none;
  }
  .parte-parametros{
    .form-group {
      height: 53px;
    }
  }
}

.form-nuevo-verraco{
  .observaciones-verraco{
    .form-group{
      height: auto;
    }
  }
}

textarea.form-control {
  height: 125px;
}
.form-evolucion{
  .form-group {
    margin-bottom: 15px;
    height: 66px;
    .help-block{
      font-size: 10px;
      .MuiSvgIcon-root-1{
        font-size: 15px;
      }
      span{
        vertical-align: super;
      }
    }
  }
}

.simple-table{
  .form-group{
    height: auto;
  }
}

span.disabled {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.boton-ubicacion-verraco{
  border: 1px solid #FF6D29;
  margin-top: 23px;
  display: inline-block;
  padding: 6px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-bottom: 14px;
  height: 29px;
  overflow: hidden;
  background: #FF6D29;
  color: #fff;
  border-radius: 6px;
  &:hover{
    color: #fff;
    border-color: #000;
    background: #000;
  }
  .error-cambio-estado{
    color: #fff;
    background: #ff5656;
    svg{
      font-size: 15px;
      vertical-align: top;
      margin-right: 4px;
    }
  }
  .bolo-ubicacion{
    width: 8px;
    height: 8px;
    background: #FF6D29;
    border-radius: 50%;
    display: inline-block;
    margin-left: 7px;
  }
}

.error-cambio-estado{
  color: #fff;
  background: #b10000 !important;
  border: 1px solid #b10000!important;
  margin-top: 5px!important;
}

.boton-cambio-estado-verraco{
  border: 1px solid #FF6D29;
  margin-top: 24px;
  display: inline-block;
  padding: 6px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  background: #FF6D29;
  color: #fff;
  border-radius: 6px;
  &:hover{
    color: #fff;
    border-color: #000;
    background: #000;
  }
  .error-cambio-estado{
    color: #fff;
    background: #ff5656;
    svg{
      font-size: 15px;
      vertical-align: top;
      margin-right: 4px;
    }
  }
  .bolo-ubicacion{
    width: 8px;
    height: 8px;
    background: #FF6D29;
    border-radius: 50%;
    display: inline-block;
    margin-left: 7px;
  }
}

.range-totales-ficha-verraco{
  padding: 0!important;
  .form-group{
    .form-control{
      height: 22px;
    }
  }
}

.botones-pie-form{
  svg{
    font-size: 16px;
  }
}

.subtitule{
  display: inline-block;
  border-left: 1px solid #d1d1d1;
  padding: 0 11px;
  margin: 0 10px;
  font-size: 14px!important;
}

.cierre-ventana-verraco{
  float: right;
  svg{
    color: #969696;
    margin-top: 6px;
    font-size: 19px;
  }
}

.p-error-cambio-estado {
  svg{
    font-size: 14px;
    vertical-align: sub;
    margin-left: 5px;
  }
}

.tabla-totales-hist-extracciones{
  margin-left: 0;
  margin-right: 0;
  .col-5{
    width: 20%;
    padding: 8px;
    text-align: center;
  }
  .totales{
    background: #d8d8d8;
    border: 1px solid #c1c1c1;
    font-weight: bold;
    text-align: center;
    padding: 8px;
  }
  .totales-valores{
    background: #fff;
    border: 1px solid #c1c1c1;
    text-align: center;
    padding: 8px;
  }
}

.no-matadero-check{
  display: block;
}

.modal-historico{
  .modal-dialog{
    width: 850px
  }
  hr{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h4{
    color: #f56600;
  }
  .titulo-parafo{
    font-size: 14px;
    color: #f56600;
    margin-left: 15px;
  }
  ul{
    li{
      svg{
        font-size: 17px!important;
        vertical-align: middle;
        margin-right: 5px;
      }
      p{
        display: inline-block;
      }
    }
  }
}
