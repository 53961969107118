.form-cliente{
  .check-cliente{
    padding: 25px 0 0 10px;
  }
}

.help-block{
  color: #a94442;
  svg{
    font-size: 13px;
  }
  .texto-alerta-cliente{
    color: #a94442;
    vertical-align: top;
    margin-left: 5px;
  }
}

.filtros-tabla-modal-direcciones-cliente{
  display: block;
  background: #f1f1f1;
  width: 100%;
  height: 30px;
  clear: both;
  border: 1px solid #d1d1d1;
  margin-bottom: 5px;
  .btn-icono{
    background: #999;
    color: #fff;
    border: none;
    padding: 2px 6px;
    outline: none;
    margin-right: 16px;
    float: left;
    margin-top: 4px;
    border-radius: 5px;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .nuevo{
    margin: 0;
    padding: 1px 6px;
    margin-left: 0px;
    margin-top: 3px;
    float: left;
    svg{
      font-size: 16px;
      vertical-align: bottom;
    }
  }
  .custom-check-w {
    width: 250px;
  }
  .col-sm-6{
    width: 47px;
  }
}
.oculta-action{
  .btn-icono{
    display: none;
  }
}


.error-verracos{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
  }
}
.noerror-verracos{
  display: none;
}
.tabla-filtros-modal-direcciones-cliente{
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding-top: 7px;
  .buttons-min{
    text-align: right;
    .button-aplicar{
      margin: 0 5px 5px 0px;
    }
  }
}
.btn > svg {
  vertical-align: middle;
}


.tab-modal-cliente{
  .nav-tabs{
    margin-bottom: 30px;
    .active{
      a{
        color: #FF6D29;
        border-bottom: 3px solid;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        &:focus, &:hover, &:active, &:visited{
          color: #FF6D29;
          border-bottom: 3px solid;
          border-top: 3px solid transparent;
          border-right: none;
          border-left: none;
        }
      }
    }
    a{
      color: #666;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-right: none;
      border-left: none;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      &:focus, &:active, &:visited{
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
      &:hover{
        color: #FF6D29;
        border-bottom: 3px solid #FF6D29;
        border-top: 3px solid transparent;
        border-right: none;
        border-left: none;
        background: none;
        outline: none;
      }
    }
  }
}

.modal-anadi-ruta {
  .modal-dialog{
    width: 300px;
  }
}
