.modal-registro-intervencion-sanitaria{
  .modal-dialog{
    width: 970px;
    .tabla-verracos-en-grupo-verracos{
      padding: 15px;
    }
    .filtros-tabla-modal-registro-intervencion-sanitaria{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-right: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .nuevo{
        margin: 0;
        padding: 1px 6px;
        margin-left: 0px;
        margin-top: 3px;
        float: left;
        svg{
          font-size: 16px;
          vertical-align: bottom
        }
      }
      .custom-check-w {
        width: 250px;
      }
      .col-sm-6{
        width: 47px;
      }
    }
    .oculta-action{
      .btn-icono{
        display: none;
        svg{
          font-size: 17px;
        }
      }
    }
  }
}

.error-verracos{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
  }
}
.noerror-verracos{
  display: none;
}
.tabla-filtros-modal-grupo-verracos{
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding-top: 7px;
  .buttons-min{
    text-align: right;
    .button-aplicar{
      margin: 0 5px 5px 0px;
      margin-top: 22px;
    }
  }
}

.archivosIntervencion{
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background: #fafafa;
  position: relative;
  margin-top: 22px;
  .custom-file-label{
    position: relative;
    margin-top: 10px;
    border: 1px solid #d1d1d1;
    background: #fff;
    display: block;
    width: 100%;
    padding: 3px 15px;
    cursor: pointer;
    .explorar-input-file{
      background: #888;
      padding: 3px 30px;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  ul{
    padding: 0;
    li{
      list-style: none;
      background: #fff;
      border: 1px solid #d1d1d1;
      margin-top: 10px;
      padding: 6px 16px;
      position: relative;
      p{
        display: inline-block;
        margin: 0px 7px;
        vertical-align: middle;
        max-width: 360px;
        overflow: hidden;
      }
      svg{
        vertical-align: middle;
        font-size: 16px;
      }
      .borrar{
        position: absolute;
        right: 9px;
        top: 4px;
        cursor: pointer;
        &:hover{
          color: #c1c1c1;
        }
      }
    }
  }
}