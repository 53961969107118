.modal-ruta{
  .modal-dialog{
    width: 900px;
    .tabla-cliente-en-ruta{
      padding: 15px;
    }
    .filtros-tabla-modal-ruta{
      display: block;
      background: #f1f1f1;
      width: 100%;
      height: 30px;
      clear: both;
      border: 1px solid #d1d1d1;
      margin-bottom: 10px;
      .btn-icono{
        background: #999;
        color: #fff;
        border: none;
        padding: 2px 6px;
        outline: none;
        margin-right: 16px;
        float: left;
        margin-top: 4px;
        border-radius: 5px;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .nuevo{
        margin: 0;
        padding: 1px 6px;
        margin-left: 0px;
        margin-top: 3px;
        float: left;
        svg{
          font-size: 16px;
          vertical-align: bottom;
        }
      }
      .col-sm-6{
        width: 47px;
      }
    }
    .oculta-action{
      .btn-icono{
        display: none;
      }
    }
  }
}

.error-cliente{
  display: block;
  color: #ad0e0e;
  clear: both;
  font-weight: bold;
  svg{
    vertical-align: bottom;
    margin-right: 5px;
  }
}
.noerror-cliente{
  display: none;
}
.tabla-filtros-modal-ruta{
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding-top: 7px;
  .buttons-min{
    text-align: right;
    .button-aplicar{
      margin: 0 5px 5px 0px;
    }
  }
}
.modal-filtros-aplicar{
  display: block;
  padding-top: 5px;
  text-align: right;
  margin-right: 10px;
}

.form-ruta{
  .custom-check-w{
    width: 250px;
  }
}
