.form-escandallo{
  .check-escandallo{
    padding: 25px 0 0 10px;
  }
}

.help-block{
  color: #a94442;
  svg{
    font-size: 16px;
  }
  .texto-alerta-escandallo{
    color: #a94442;
    vertical-align: top;
    margin-left: 5px;
  }
}